import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import * as fromAppReducers from 'tenant-pool/+state/reducers';
import { notificationConfig as notification } from 'tenant-pool/config';
import { RentDepositStepRoutes } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-step.routes';

import { RentalDepositFacade } from 'tenant-pool/core/services/rental-deposit.facade';
import { Go, ShowError, ShowInfo } from '@ui/legacy-lib';
import * as fromActions from './rent-deposit.actions';
import * as fromSelectors from './rent-deposit.selectors';

@Injectable()
export class RentDepositEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private facade = inject(RentalDepositFacade);

  nextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RentDepositWizardNextStep),
      withLatestFrom(this.store.select(fromSelectors.getCurrentWizardStep)),
      map(([{ id }, currentStepNumber]) => {
        return new Go({
          path: [
            'rent-deposit',
            id,
            RentDepositStepRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  prevStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RentDepositWizardPreviousStep),
      withLatestFrom(this.store.select(fromSelectors.getCurrentWizardStep)),
      map(([{ id }, currentStepNumber]) => {
        return new Go({
          path: [
            'rent-deposit',
            id,
            RentDepositStepRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  goToStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RentDepositWizardGoToStep),
      map(({ id, stepNumber }) => {
        return new Go({
          path: ['rent-deposit', id, RentDepositStepRoutes[stepNumber].path]
        });
      })
    )
  );

  useRuvDeposit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UseRuvDeposit),
      switchMap(({ propertyId, input }) =>
        this.facade.useRuvDeposit(propertyId, input).pipe(
          mergeMap(rentDepositInfo => [
            fromActions.UseRuvDepositSuccess({ rentDepositInfo }),
            new ShowInfo(notification.rentDeposit.useRuvDeposit.success)
          ]),
          catchError(error => [
            fromActions.UseRuvDepositFail(error),
            new ShowError(notification.rentDeposit.useRuvDeposit.error)
          ])
        )
      )
    )
  );

  useCashDeposit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UseCashDeposit),
      switchMap(({ propertyId }) =>
        this.facade.useCashDeposit(propertyId).pipe(
          mergeMap(() => [
            fromActions.UseCashDepositSuccess(),
            new ShowInfo(notification.rentDeposit.useCashDeposit.success)
          ]),
          catchError(error => [
            fromActions.UseCashDepositFail(error),
            new ShowError(notification.rentDeposit.useCashDeposit.error)
          ])
        )
      )
    )
  );

  loadDepositOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadDepositInfo),
      switchMap(({ propertyId }) =>
        this.facade.loadDepositInfo(propertyId).pipe(
          map(depositInfo =>
            fromActions.LoadDepositInfoSuccess({ depositInfo })
          ),
          catchError(error => [
            fromActions.LoadDepositInfoFail(error),
            new ShowError(notification.rentDeposit.loadDepositOptions.error)
          ])
        )
      )
    )
  );
}
