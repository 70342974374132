import { Action } from '@ngrx/store';
import {
  SocialLogin,
  PropertySearcherUser,
  AvailableLanguageCodesEnum,
  UserRegisteredPlatform
} from '@ui/shared/models';
import { UserData } from 'tenant-pool/models';

export const REGISTER = '[User] Register';
export const REGISTER_FAIL = '[User] Register Fail';
export const REGISTER_SUCCESS = '[User] Register Success';
export const CONTINUE_REGISTER = '[User] Continue Register';

export class Register implements Action {
  readonly type = REGISTER;
  constructor(public userData: UserData) {}
}

export class RegisterSuccess implements Action {
  readonly type = REGISTER_SUCCESS;
}

export class RegisterFail implements Action {
  readonly type = REGISTER_FAIL;
  constructor(public error: Error) {}
}

export class ContinueRegister implements Action {
  readonly type = CONTINUE_REGISTER;
  constructor(public userData: UserData) {}
}

export const LOAD_USER_DATA = '[User] Load User Data';
export const LOAD_USER_DATA_FAIL = '[User] Load User Data Fail';
export const LOAD_USER_DATA_SUCCESS = '[User] Load User Data Success';

export class LoadUserData implements Action {
  readonly type = LOAD_USER_DATA;
}

export class LoadUserDataSuccess implements Action {
  readonly type = LOAD_USER_DATA_SUCCESS;
  constructor(public user: PropertySearcherUser) {}
}

export class LoadUserDataFail implements Action {
  readonly type = LOAD_USER_DATA_FAIL;
  constructor(public error: string) {}
}

export const SAVE_USER_DATA = '[User] Save User Data';
export const SAVE_USER_DATA_FAIL = '[User] Save User Data Fail';
export const SAVE_USER_DATA_SUCCESS = '[User] Save User Data Success';

export class SaveUserData implements Action {
  readonly type = SAVE_USER_DATA;
  constructor(public user: PropertySearcherUser) {}
}

export class SaveUserDataSuccess implements Action {
  readonly type = SAVE_USER_DATA_SUCCESS;
  constructor(public user: PropertySearcherUser) {}
}

export class SaveUserDataFail implements Action {
  readonly type = SAVE_USER_DATA_FAIL;
  constructor(public error: string) {}
}

export const DELETE_USER = '[User] Delete User';
export const DELETE_USER_FAIL = '[User] Delete User Fail';
export const DELETE_USER_SUCCESS = '[User] Delete User Success';

export class DeleteUser implements Action {
  readonly type = DELETE_USER;
}

export class DeleteUserSuccess implements Action {
  readonly type = DELETE_USER_SUCCESS;
  constructor(public redirectUri: string) {}
}

export class DeleteUserFail implements Action {
  readonly type = DELETE_USER_FAIL;
  constructor(public error: Error) {}
}

export const CHANGE_EMAIL = '[User] Change Email';
export const CHANGE_EMAIL_FAIL = '[User] Change Email Fail';
export const CHANGE_EMAIL_SUCCESS = '[User] Change Email Success';

export class ChangeEmail implements Action {
  readonly type = CHANGE_EMAIL;

  constructor(
    public newEmail: string,
    public confirmEmail: string
  ) {}
}

export class ChangeEmailFail implements Action {
  readonly type = CHANGE_EMAIL_FAIL;

  constructor(public error: Error) {}
}

export class ChangeEmailSuccess implements Action {
  readonly type = CHANGE_EMAIL_SUCCESS;
}

export const CHANGE_PASSWORD = '[User] Change Password';
export const CHANGE_PASSWORD_FAIL = '[User] Change Password Fail';
export const CHANGE_PASSWORD_SUCCESS = '[User] Change Password Success';

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;

  constructor(
    public password: string,
    public confirmedPassword: string
  ) {}
}

export class ChangePasswordFail implements Action {
  readonly type = CHANGE_PASSWORD_FAIL;

  constructor(public error: Error) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = CHANGE_PASSWORD_SUCCESS;
}

export const SET_SEARCHING_STATUS = '[User] Set Searching Status';
export const SET_SEARCHING_STATUS_FAIL = '[User] Set Searching Status Fail';
export const SET_SEARCHING_STATUS_SUCCESS =
  '[User] Set Searching Status Success';

export class SetSearchingStatus implements Action {
  readonly type = SET_SEARCHING_STATUS;

  constructor(
    public token: string,
    public isSearching: boolean
  ) {}
}

export class SetSearchingStatusFail implements Action {
  readonly type = SET_SEARCHING_STATUS_FAIL;

  constructor(public error: Error) {}
}

export class SetSearchingStatusSuccess implements Action {
  readonly type = SET_SEARCHING_STATUS_SUCCESS;
}

export const RESEND_VERIFICATION_EMAIL = '[User] Resend Verification Email';
export const RESEND_VERIFICATION_EMAIL_FAIL =
  '[User] Resend Verification Email Fail';

export class ResendVerificationEmail implements Action {
  readonly type = RESEND_VERIFICATION_EMAIL;
}

export class ResendVerificationEmailFail implements Action {
  readonly type = RESEND_VERIFICATION_EMAIL_FAIL;
  constructor(public error: Error) {}
}

export const CHECK_FIRST_SOCIAL_LOGIN = '[User] Check First Social Login';
export const CHECK_FIRST_SOCIAL_LOGIN_FAIL =
  '[User] Check First Social Login Fail';
export const CHECK_FIRST_SOCIAL_LOGIN_SUCCESS =
  '[User] Check First Social Login Success';

export class CheckFirstSocialLogin implements Action {
  readonly type = CHECK_FIRST_SOCIAL_LOGIN;

  constructor(public email: string) {}
}

export class CheckFirstSocialLoginFail implements Action {
  readonly type = CHECK_FIRST_SOCIAL_LOGIN_FAIL;

  constructor(public socialLogin: SocialLogin) {}
}

export class CheckFirstSocialLoginSuccess implements Action {
  readonly type = CHECK_FIRST_SOCIAL_LOGIN_SUCCESS;

  constructor(public socialLogin: SocialLogin) {}
}

export const UNLOCK_ACCOUNT = '[User] Unlock Account';
export const UNLOCK_ACCOUNT_FAIL = '[User] Unlock Account Fail';
export const UNLOCK_ACCOUNT_SUCCESS = '[User] Unlock Account Success';

export class UnlockAccount implements Action {
  readonly type = UNLOCK_ACCOUNT;

  constructor(public token: string) {}
}

export class UnlockAccountFail implements Action {
  readonly type = UNLOCK_ACCOUNT_FAIL;

  constructor(public error: Error) {}
}

export class UnlockAccountSuccess implements Action {
  readonly type = UNLOCK_ACCOUNT_SUCCESS;
}

export const OPEN_ACTIVENESS_MODAL = '[User] Open Activeness Modal';

export class OpenActivenessModal implements Action {
  readonly type = OPEN_ACTIVENESS_MODAL;

  constructor(
    public titleMessage: string,
    public message: string,
    public okButtonMessage: string
  ) {}
}

export const LEAVE_INTERNAL_TENANT_POOL = '[User] Leave Internal Tenant Pool';
export const LEAVE_INTERNAL_TENANT_POOL_FAIL =
  '[User] Leave Internal Tenant Pool Fail';
export const LEAVE_INTERNAL_TENANT_POOL_SUCCESS =
  '[User] Leave Internal Tenant Pool Success';

export class LeaveInternalTenantPool implements Action {
  readonly type = LEAVE_INTERNAL_TENANT_POOL;
}

export class LeaveInternalTenantPoolFail implements Action {
  readonly type = LEAVE_INTERNAL_TENANT_POOL_FAIL;
  constructor(public error: Error) {}
}

export class LeaveInternalTenantPoolSuccess implements Action {
  readonly type = LEAVE_INTERNAL_TENANT_POOL_SUCCESS;
}

export const ASSIGN_REGISTRATION_TOKEN = '[User] Assign Registration Token';
export const ASSIGN_REGISTRATION_TOKEN_SUCCESS =
  '[User] Assign Registration Token Success';
export const ASSIGN_REGISTRATION_TOKEN_FAIL =
  '[User] Assign Registration Token Fail';

export class AssignRegistrationToken implements Action {
  readonly type = ASSIGN_REGISTRATION_TOKEN;
  constructor(public tokenId: string) {}
}

export class AssignRegistrationTokenSuccess implements Action {
  readonly type = ASSIGN_REGISTRATION_TOKEN_SUCCESS;
}

export class AssignRegistrationTokenFail implements Action {
  readonly type = ASSIGN_REGISTRATION_TOKEN_FAIL;
  constructor(public err: Error) {}
}

export const SAGA_DATA_TRANSFER = '[Saga] Data Transfer';
export const SAGA_DATA_TRANSFER_RESPONSE_SUCCESS =
  '[Saga] Data Transfer Response Success';
export const SAGA_DATA_TRANSFER_RESPONSE_FAIL =
  '[Saga] Data Transfer Response Fail';

export class SagaDataTransfer implements Action {
  readonly type = SAGA_DATA_TRANSFER;
  constructor(
    public accept: boolean,
    public token: string
  ) {}
}

export class SagaDataTransferResponseSuccess implements Action {
  readonly type = SAGA_DATA_TRANSFER_RESPONSE_SUCCESS;
}

export class SagaDataTransferResponseFail implements Action {
  readonly type = SAGA_DATA_TRANSFER_RESPONSE_FAIL;
  constructor(public error: Error) {}
}

export const CHANGE_PREFERRED_LANGUAGE = '[User] Change Preferred Language';
export const CHANGE_PREFERRED_LANGUAGE_SUCCESS =
  '[User] Change Preferred Language Success';
export const CHANGE_PREFERRED_LANGUAGE_FAIL =
  '[User] Change Preferred Language Fail';

export class ChangePreferredLanguage implements Action {
  readonly type = CHANGE_PREFERRED_LANGUAGE;
  constructor(
    public languageCode: AvailableLanguageCodesEnum,
    public reload: boolean
  ) {}
}

export class ChangePreferredLanguageSuccess implements Action {
  readonly type = CHANGE_PREFERRED_LANGUAGE_SUCCESS;
  constructor(
    public languageCode: AvailableLanguageCodesEnum,
    public reload: boolean
  ) {}
}

export class ChangePreferredLanguageFail implements Action {
  readonly type = CHANGE_PREFERRED_LANGUAGE_FAIL;
  constructor(public error: Error) {}
}

export const FETCH_PREFERRED_LANGUAGE = '[User] Fetch Preferred Language';
export const FETCH_PREFERRED_LANGUAGE_SUCCESS =
  '[User] Fetch Preferred Language Success';
export const FETCH_PREFERRED_LANGUAGE_FAIL =
  '[User] Fetch Preferred Language Fail';

export class FetchPreferredLanguage implements Action {
  readonly type = FETCH_PREFERRED_LANGUAGE;
}

export class FetchPreferredLanguageSuccess implements Action {
  readonly type = FETCH_PREFERRED_LANGUAGE_SUCCESS;
  constructor(public languageCode: AvailableLanguageCodesEnum) {}
}

export class FetchPreferredLanguageFail implements Action {
  readonly type = FETCH_PREFERRED_LANGUAGE_FAIL;
  constructor(public error: Error) {}
}

export const FETCH_IS_REGISTERED_AT_IMMOMIO =
  '[User] Fetch Is Registered At Immomio';
export const FETCH_IS_REGISTERED_AT_IMMOMIO_SUCCESS =
  '[User] Fetch Is Registered At Immomio Success';
export const FETCH_IS_REGISTERED_AT_IMMOMIO_FAIL =
  '[User] Fetch Is Registered At Immomio Fail';

export class FetchIsRegisteredAtImmomio implements Action {
  readonly type = FETCH_IS_REGISTERED_AT_IMMOMIO;
}

export class FetchIsRegisteredAtImmomioSuccess implements Action {
  readonly type = FETCH_IS_REGISTERED_AT_IMMOMIO_SUCCESS;
  constructor(public userRegisteredPlatform: UserRegisteredPlatform) {}
}

export class FetchIsRegisteredAtImmomioFail implements Action {
  readonly type = FETCH_IS_REGISTERED_AT_IMMOMIO_FAIL;
  constructor(public error: Error) {}
}

export const REGISTER_RESIDENT = '[User] Register Resident';
export const REGISTER_RESIDENT_SUCCESS = '[User] Register Resident Success';
export const REGISTER_RESIDENT_FAIL = '[User] Register Resident Fail';

export class RegisterResident implements Action {
  readonly type = REGISTER_RESIDENT;
}

export class RegisterResidentSuccess implements Action {
  readonly type = REGISTER_RESIDENT_SUCCESS;
}

export class RegisterResidentFail implements Action {
  readonly type = REGISTER_RESIDENT_FAIL;
  constructor(public error: Error) {}
}

export type UserAction =
  | Register
  | RegisterSuccess
  | RegisterFail
  | ContinueRegister
  | LoadUserData
  | LoadUserDataFail
  | LoadUserDataSuccess
  | SaveUserData
  | SaveUserDataFail
  | SaveUserDataSuccess
  | DeleteUser
  | DeleteUserFail
  | DeleteUserSuccess
  | ChangeEmail
  | ChangeEmailFail
  | ChangeEmailSuccess
  | ChangePassword
  | ChangePasswordFail
  | ChangePasswordSuccess
  | SetSearchingStatus
  | SetSearchingStatusFail
  | SetSearchingStatusSuccess
  | ResendVerificationEmail
  | ResendVerificationEmailFail
  | CheckFirstSocialLogin
  | CheckFirstSocialLoginFail
  | CheckFirstSocialLoginSuccess
  | UnlockAccount
  | UnlockAccountFail
  | UnlockAccountSuccess
  | OpenActivenessModal
  | LeaveInternalTenantPool
  | LeaveInternalTenantPoolFail
  | LeaveInternalTenantPoolSuccess
  | AssignRegistrationToken
  | AssignRegistrationTokenFail
  | AssignRegistrationTokenSuccess
  | SagaDataTransfer
  | SagaDataTransferResponseFail
  | SagaDataTransferResponseSuccess
  | ChangePreferredLanguage
  | ChangePreferredLanguageSuccess
  | ChangePreferredLanguageFail
  | FetchPreferredLanguage
  | FetchPreferredLanguageSuccess
  | FetchPreferredLanguageFail
  | FetchIsRegisteredAtImmomio
  | FetchIsRegisteredAtImmomioSuccess
  | FetchIsRegisteredAtImmomioFail
  | RegisterResident
  | RegisterResidentSuccess
  | RegisterResidentFail;
