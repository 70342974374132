import { Action } from '@ngrx/store';

import {
  DigitalContract,
  DocuSignResponse,
  CurrentState,
  TenantInformation,
  SignatureType,
  QesCheck
} from '@ui/shared/models';

export const LOAD_DIGITAL_CONTRACT = '[DigitalContract] Fetch Digital Contract';
export const LOAD_DIGITAL_CONTRACT_SUCCESS =
  '[DigitalContract] Fetch Digital Contract Success';
export const LOAD_DIGITAL_CONTRACT_FAIL =
  '[DigitalContract] Fetch Digital Contract Fail';

export class LoadDigitalContract implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT;

  constructor(public token: string) {}
}

export class LoadDigitalContractSuccess implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_SUCCESS;

  constructor(public contract: DigitalContract) {}
}

export class LoadDigitalContractFail implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_FAIL;

  constructor(public error: Error) {}
}

export const DOWNLOAD_DIGITAL_CONTRACT =
  '[DigitalContract] Download Digital Contract';
export const DOWNLOAD_DIGITAL_CONTRACT_SUCCESS =
  '[DigitalContract] Download Digital Contract Success';
export const DOWNLOAD_DIGITAL_CONTRACT_FAIL =
  '[DigitalContract] Download Digital Contract Fail';

export class DownloadContract implements Action {
  readonly type = DOWNLOAD_DIGITAL_CONTRACT;

  constructor(
    public token: string,
    public identifier: string
  ) {}
}

export class DownloadContractSuccess implements Action {
  readonly type = DOWNLOAD_DIGITAL_CONTRACT_SUCCESS;
}

export class DownloadContractFail implements Action {
  readonly type = DOWNLOAD_DIGITAL_CONTRACT_FAIL;

  constructor(public error: Error) {}
}

export const SEND_MESSAGE = '[DigitalContract] Send Message';
export const SEND_MESSAGE_SUCCESS = '[DigitalContract] Send Message Success';
export const SEND_MESSAGE_FAIL = '[DigitalContract] Send Message Fail';

export class SendMessage implements Action {
  readonly type = SEND_MESSAGE;

  constructor(public token: string) {}
}

export class SendMessageSuccess implements Action {
  readonly type = SEND_MESSAGE_SUCCESS;
}

export class SendMessageFail implements Action {
  readonly type = SEND_MESSAGE_FAIL;

  constructor(public error: Error) {}
}

export const SEND_TENANT_INFO = '[DigitalContract] Send Tenant Info';
export const SEND_TENANT_INFO_SUCCESS =
  '[DigitalContract] Send Tenant Info Success';
export const SEND_TENANT_INFO_FAIL = '[DigitalContract] Send Tenant Info Fail';

export class SendTenantInfo implements Action {
  readonly type = SEND_TENANT_INFO;

  constructor(
    public info: TenantInformation,
    public signatureType: SignatureType
  ) {}
}

export class SendTenantInfoSuccess implements Action {
  readonly type = SEND_TENANT_INFO_SUCCESS;

  constructor(public docuSignResponse: DocuSignResponse) {}
}

export class SendTenantInfoFail implements Action {
  readonly type = SEND_TENANT_INFO_FAIL;

  constructor(public error: Error) {}
}

export const GO_TO_DOCUSIGN = '[DigitalContract] Go To DocuSign';

export class GoToDocuSign implements Action {
  readonly type = GO_TO_DOCUSIGN;

  constructor(public docuSignUrl: string) {}
}

export const SIGN = '[DigitalContract] Sign';
export const VERIFY_CODE = '[DigitalContract] Verify Code';

export class Sign implements Action {
  readonly type = SIGN;
}

export class VerifyCode implements Action {
  readonly type = VERIFY_CODE;
}

export const GET_SIGNING_URL = '[DigitalContract] Get Signing Url';
export const GET_SIGNING_URL_SUCCESS =
  '[DigitalContract] Get Signing Url Success';
export const GET_SIGNING_URL_FAIL = '[DigitalContract] Get Signing Url Fail';

export class GetSigningUrl implements Action {
  readonly type = GET_SIGNING_URL;

  constructor(
    public token: string,
    public redirectUrl: string
  ) {}
}

export class GetSigningUrlSuccess implements Action {
  readonly type = GET_SIGNING_URL_SUCCESS;

  constructor(public docuSignResponse: DocuSignResponse) {}
}

export class GetSigningUrlFail implements Action {
  readonly type = GET_SIGNING_URL_FAIL;

  constructor(public error: Error) {}
}

export const GET_CURRENT_STATE = '[DigitalContract] Get Current Status';
export const GET_CURRENT_STATE_SUCCESS =
  '[DigitalContract] Get Current Success';
export const GET_CURRENT_STATE_FAIL = '[DigitalContract] Get Current Fail';

export class GetCurrentState implements Action {
  readonly type = GET_CURRENT_STATE;

  constructor(public token: string) {}
}

export class GetCurrentStateSuccess implements Action {
  readonly type = GET_CURRENT_STATE_SUCCESS;

  constructor(public currentState: CurrentState) {}
}

export class GetCurrentStateFail implements Action {
  readonly type = GET_CURRENT_STATE_FAIL;

  constructor(public error: Error) {}
}

export const START_QES_CHECK = '[DigitalContract] Start Qes Check';
export const START_QES_CHECK_SUCCESS =
  '[DigitalContract] Start Qes Check Success';
export const START_QES_CHECK_FAIL = '[DigitalContract] Start Qes Check Fail';

export class StartQesCheck implements Action {
  readonly type = START_QES_CHECK;

  constructor(public qesCheck: QesCheck) {}
}

export class StartQesCheckSuccess implements Action {
  readonly type = START_QES_CHECK_SUCCESS;

  constructor(public link: string) {}
}

export class StartQesCheckFail implements Action {
  readonly type = START_QES_CHECK_FAIL;

  constructor(public error: Error) {}
}

export const DIGITAL_CONTRACT_WIZARD_NEXT_STEP =
  '[DigitalContracts Wizard] Next Step';
export const DIGITAL_CONTRACT_WIZARD_GO_TO_STEP =
  '[DigitalContracts Wizard] Go to Step';
export const DIGITAL_CONTRACT_WIZARD_PREVIOUS_STEP =
  '[DigitalContracts Wizard] Previous Step';

export class DigitalContractWizardNextStep implements Action {
  readonly type = DIGITAL_CONTRACT_WIZARD_NEXT_STEP;

  constructor(
    public completedStep: string,
    public contractId?: string
  ) {}
}

export class DigitalContractWizardGoToStep implements Action {
  readonly type = DIGITAL_CONTRACT_WIZARD_GO_TO_STEP;

  constructor(public stepNumber: number) {}
}

export class DigitalContractWizardPreviousStep implements Action {
  readonly type = DIGITAL_CONTRACT_WIZARD_PREVIOUS_STEP;

  constructor(public contractId?: string) {}
}

export type digitalContractsAction =
  | LoadDigitalContract
  | LoadDigitalContractFail
  | LoadDigitalContractSuccess
  | DownloadContract
  | DownloadContractFail
  | DownloadContractSuccess
  | GetSigningUrl
  | GetSigningUrlFail
  | GetSigningUrlSuccess
  | GetCurrentState
  | GetCurrentStateFail
  | GetCurrentStateSuccess
  | StartQesCheck
  | StartQesCheckSuccess
  | StartQesCheckFail
  | Sign
  | SendMessage
  | SendMessageFail
  | SendMessageSuccess
  | SendTenantInfo
  | SendTenantInfoFail
  | SendTenantInfoSuccess
  | DigitalContractWizardGoToStep
  | DigitalContractWizardPreviousStep
  | DigitalContractWizardNextStep;
