import { gql } from 'apollo-angular';
import {
  CustomerBranding,
  KeyValuePair,
  PaymentMandate,
  PaymentPayInResponse,
  PaymentRequest,
  SmartDepositGuaranteeProduct,
  SmartDepositOverview,
  PaymentTokenBean
} from '@ui/shared/models';

import { addressFields } from '@ui/legacy-lib';
import { customerBrandingFields } from 'tenant-pool/core/queries/user.queries';

export interface FetchPaymentRequestResponse {
  fetchPaymentRequest: PaymentRequest;
}

export interface FetchMandateResponse {
  fetchMandate: PaymentMandate;
}

export interface FetchPaymentBrandingResponse {
  fetchPaymentBranding: CustomerBranding;
}

export interface PaymentExecutionResponse {
  paymentExecution: PaymentPayInResponse;
}

export interface LoadSmartDepositDictionaryResponse {
  smartDepositDictionary: {
    salutation: KeyValuePair<number, string>[];
    'number-of-children': KeyValuePair<number, string>[];
    'commercial-sector': KeyValuePair<number, string>[];
    occupation: KeyValuePair<number, string>[];
    'marital-status': KeyValuePair<number, string>[];
    country: KeyValuePair<string, string>[];
    postcode: KeyValuePair<string, string[]>[];
    'way-of-living': KeyValuePair<number, string>[];
  };
}

export interface LoadSmartDepositFeeResponse {
  smartDepositFee: SmartDepositGuaranteeProduct;
}

export interface FetchSmartDepositResponse {
  fetchSmartDeposit: SmartDepositOverview;
}

export interface LoadSmartDepositBrandingResponse {
  smartDepositBranding: CustomerBranding;
}

export interface ApplySmartDepositResponse {
  applySmartDeposit: PaymentTokenBean;
}

export const SmartDepositGuaranteeProductFields = `
  commission
  guaranteeFee
  commissionAmount
`;

export const SmartDepositApplicationStateFields = `
  applicationStatus
  legitimationStatus
`;

export const SmartDepositApplicantContactFields = `
  email
  telephone
`;

export const SmartDepositApplicationOverviewFields = `
  id
  currentState {
    ${SmartDepositApplicationStateFields}
  }
  offer {
    ${SmartDepositGuaranteeProductFields}
  }
  applicantContact {
    ${SmartDepositApplicantContactFields}
  }
  legitimationContact {
    ${SmartDepositApplicantContactFields}
  }
`;

export const SmartDepositPreFillDataFields = `
  landlordName
  landlordAddress {
    ${addressFields}
  }
  propertyAddress {
    ${addressFields}
  }
  depositAmount
  startOfContract
  baseRent
`;

export const SmartDepositOverviewFields = `
  id
  status
  preFillData {
    ${SmartDepositPreFillDataFields}
  }
  smartDepositApplication {
    ${SmartDepositApplicationOverviewFields}
  }
`;

// Queries

export const fetchPaymentRequestQuery = gql`
  query fetchPaymentRequest($token: ID!) {
    fetchPaymentRequest(token: $token) {
      positions {
        id
        subject
        amount
        status
      }
      customerName
      allowedPaymentMethods
    }
  }
`;

export const fetchMandateQuery = gql`
  query fetchMandate($token: ID!, $mandateId: ID!) {
    fetchMandate(token: $token, mandateId: $mandateId) {
      id
      status
      creationDate
    }
  }
`;

export const fetchPaymentBrandingQuery = gql`
  query fetchPaymentBranding($token: ID!) {
    fetchPaymentBranding(token: $token) {
      ${customerBrandingFields}
    }
  }
`;

export const fetchSmartDepositQuery = gql`
  query fetchSmartDeposit($token: ID!) {
    fetchSmartDeposit(token: $token) {
      ${SmartDepositOverviewFields}
    }
  }
`;

export const smartDepositDictionaryQuery = gql`
  query smartDepositDictionary {
    smartDepositDictionary
  }
`;

export const smartDepositFeeQuery = gql`
  query smartDepositFee($deposit: Int) {
    smartDepositFee(deposit: $deposit) {
      ${SmartDepositGuaranteeProductFields}
    }
  }
`;

export const smartDepositBrandingQuery = gql`
  query smartDepositBranding($token: ID!) {
    smartDepositBranding(token: $token) {
      ${customerBrandingFields}
    }
  }
`;

// Mutations

export const manualPaymentMutation = gql`
  mutation manualPayment($input: ManualPaymentInput!) {
    manualPayment(input: $input) {
      status
    }
  }
`;

export const paymentExecutionMutation = gql`
  mutation paymentExecution($input: PaymentExecutionInput!) {
    paymentExecution(input: $input) {
      redirectUrl
    }
  }
`;

export const applySmartDepositMutation = gql`
  mutation applySmartDeposit(
    $token: ID!
    $smartDepositApplicationPayload: SmartDepositRequestOfferInput!
  ) {
    applySmartDeposit(
      token: $token
      smartDepositApplicationPayload: $smartDepositApplicationPayload
    ) {
      token
    }
  }
`;

export const cancelSmartDepositMutation = gql`
  mutation cancelSmartDeposit($token: ID!) {
    cancelSmartDeposit(token: $token) {
      status
    }
  }
`;

export const startSmartDepositLegitimationMutation = gql`
  mutation startSmartDepositLegitimation(
    $token: ID!
    $input: SmartDepositLegitimationInput
  ) {
    startSmartDepositLegitimation(token: $token, input: $input) {
      status
    }
  }
`;

export const cancelSmartDepositLegitimationMutation = gql`
  mutation cancelSmartDepositLegitimation($token: ID!) {
    cancelSmartDepositLegitimation(token: $token) {
      status
    }
  }
`;
