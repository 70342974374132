import { createSelector } from '@ngrx/store';

import { getPropertyMatchesState } from 'tenant-pool/+state/reducers';
import { PropertyMatchBean } from '@ui/shared/models';
import { getRouterParams } from '@ui/legacy-lib';
import * as fromReducer from './property-matches.reducer';

export const getPropertyMatchesDataActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesDataActionState
);

export const getPropertyMatchDataActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchDataActionState
);

export const getPropertyMatchesData = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesData
);

export const getPropertyMatchesRented = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesRented
);

export const getPropertyMatchesRentedActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesRentedActionState
);

export const getPropertyMatchData = createSelector(
  getPropertyMatchesData,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  (data, props) => data.find(i => i.id === props.id) as PropertyMatchBean
);

export const getPropertyMatchDataByPropertyId = (id: string) =>
  createSelector(getPropertyMatchesData, propertyMatchesData =>
    propertyMatchesData.find(pm => pm.property?.id === id)
  );

export const getPropertyMatchRentedData = createSelector(
  getPropertyMatchesRented,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  (data, props) => data.find(i => i.id === props.id)
);

export const getPropertyMatchesPagination = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesPagination
);

export const getExposeData = createSelector(
  getRouterParams,
  getPropertyMatchesData,
  (params, applications) => {
    return [...applications].find(i => i.id === params.id);
  }
);

export const getCurrentProposal = createSelector(
  getPropertyMatchesData,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  (applications, props) => applications.find(a => a.id === props.proposalId)
);

export const getProcessApplicationActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getProcessApplicationActionState
);

export const getProcessAnonymousApplicationActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getProcessAnonymousApplicationActionState
);

export const getAvailableForNewConversation = createSelector(
  getPropertyMatchesState,
  fromReducer.getAvailableForNewConversation
);

export const getAvailableForNewConversationActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getAvailableForNewConversationActionState
);

export const getCheckApplicationExistActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getCheckApplicationExistActionState
);
export const getPropertyMatchesDataLoadMoreActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchesDataLoadMoreActionState
);

export const getPropertyMatchesQuestions = createSelector(
  getPropertyMatchesState,
  v => v.propertyMatchQuestions
);

export const getPropertyMatchQuestionsActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getPropertyMatchQuestionsActionState
);

export const getCheckIdentityActionState = createSelector(
  getPropertyMatchesState,
  fromReducer.getCheckIdentityActionState
);
