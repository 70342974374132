import { ActionStateCreator, ActionState } from '@ui/legacy-lib';
import { PropertyMatchBean, SelfDisclosureModel } from '@ui/shared/models';
import * as fromActions from './self-disclosure.actions';

export interface SelfDisclosureState {
  selfDisclosureModel: SelfDisclosureModel;
  selfDisclosureResponses: SelfDisclosureModel;
  propertyId: string;
  propertySearcher: PropertyMatchBean;
  saveAnswersActionState: ActionState;
  loadModelActionState: ActionState;
  loadResponsesActionState: ActionState;
}

export const initialState: SelfDisclosureState = {
  selfDisclosureModel: null,
  selfDisclosureResponses: null,
  propertyId: null,
  propertySearcher: null,
  saveAnswersActionState: ActionStateCreator.create(),
  loadModelActionState: ActionStateCreator.create(),
  loadResponsesActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.SelfDisclosureAction
): SelfDisclosureState {
  switch (action.type) {
    case fromActions.SAVE_PROPERTY_SEARCHER: {
      return {
        ...state,
        propertySearcher: action.propertySearcher
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_MODEL_SUCCESS: {
      return {
        ...state,
        selfDisclosureModel: action.selfDisclosureModel,
        loadModelActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_MODEL: {
      return {
        ...state,
        loadModelActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_MODEL_FAIL: {
      return {
        ...state,
        loadModelActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_ANSWERS: {
      return {
        ...state,
        loadResponsesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_ANSWERS_SUCCESS: {
      return {
        ...state,
        selfDisclosureResponses: action.selfDisclosureResponses,
        loadResponsesActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_SELF_DISCLOSURE_ANSWERS_FAIL: {
      return {
        ...state,
        loadResponsesActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SAVE_SELF_DISCLOSURE_ANSWERS: {
      return {
        ...state,
        saveAnswersActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SAVE_SELF_DISCLOSURE_ANSWERS_SUCCESS: {
      return {
        ...state,
        selfDisclosureResponses: action.selfDisclosureResponses,
        saveAnswersActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SAVE_SELF_DISCLOSURE_ANSWERS_FAIL: {
      return {
        ...state,
        saveAnswersActionState: ActionStateCreator.onError(action.error)
      };
    }

    default:
      return state;
  }
}

export const getSaveSelfDisclosureAnswersActionState = (
  state: SelfDisclosureState
) => state.saveAnswersActionState;
export const getLoadModelActionState = (state: SelfDisclosureState) =>
  state.loadModelActionState;
export const getLoadResponsesActionState = (state: SelfDisclosureState) =>
  state.loadResponsesActionState;
export const getSelfDisclosureModel = (state: SelfDisclosureState) =>
  state.selfDisclosureModel;
export const getSelfDisclosureResponses = (state: SelfDisclosureState) =>
  state.selfDisclosureResponses;
export const getSelfDisclosurePropertyId = (state: SelfDisclosureState) =>
  state.propertyId;
export const getSelfDisclosurePropertySearcher = (state: SelfDisclosureState) =>
  state.propertySearcher;
