import { Component, forwardRef, Input, OnInit, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  RentDepositDeliveryOption,
  RentDepositPaymentOption
} from '@ui/shared/models';
import { ibanValidator } from 'ngx-iban';

import { NameValue } from '@ui/shared/models';

import { RentDepositBaseStep } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-base-step.directive';
import { IbanCheckerService } from '@ui/legacy-lib';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-rent-deposit-payment',
  templateUrl: './rent-deposit-payment.component.html',
  styleUrls: ['./rent-deposit-payment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RentDepositPaymentComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RentDepositPaymentComponent),
      multi: true
    },
    IbanCheckerService
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class RentDepositPaymentComponent
  extends RentDepositBaseStep
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);
  private ibanService = inject(IbanCheckerService);

  @Input() paymentOptions: NameValue<string>[];
  @Input() deliveryOptions: NameValue<string>[];

  public formGroup: FormGroup;

  private onChange: (args) => any = () => null;
  private onTouch: () => any = () => null;

  public get deliveryDataForm() {
    return this.formGroup.get('deliveryData') as FormGroup;
  }

  public get bankDataForm() {
    return this.formGroup.get('bankData') as FormGroup;
  }

  public get addressForm() {
    return this.deliveryDataForm.get('landlordAddress');
  }

  public get deliveryOptionForm() {
    return this.deliveryDataForm.get('selectedDeliveryOption');
  }

  public get ibanControl() {
    return this.bankDataForm.get('iban');
  }

  public get bankNameControl() {
    return this.bankDataForm.get('bankName');
  }

  public get sendDocumentsToLandlord() {
    return (
      this.deliveryDataForm.get('selectedDeliveryOption').value ===
      RentDepositDeliveryOption.LANDLORD
    );
  }

  public get sendToTenant() {
    return (
      this.deliveryDataForm.get('selectedDeliveryOption').value ===
      RentDepositDeliveryOption.PROPERTY_SEARCHER
    );
  }

  public get addressFormConfig() {
    return { countryName: true, readOnlyCountryName: true };
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      bankData: this.fb.group({
        iban: ['', Validators.compose([Validators.required, ibanValidator()])],
        bankName: [null, Validators.maxLength(30)]
      }),
      selectedPaymentOption: RentDepositPaymentOption.YEARLY,
      deliveryData: this.fb.group({
        selectedDeliveryOption: [],
        sendDocumentsToLandlord: []
      })
    });

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });

    this.ibanControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(iban => this.ibanService.getBankNameByIban(iban)),
        untilDestroyed(this)
      )
      .subscribe(bankData => {
        this.bankNameControl.patchValue(bankData?.bankData?.name);
      });
  }

  public nextStep() {
    super.nextStep();
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }
}
