import { gql } from 'apollo-angular';
import { addressGQL } from '@ui/legacy-lib';
import {
  DigitalContract,
  CurrentState,
  DocuSignResponse
} from '@ui/shared/models';
import { customerBrandingFields } from 'tenant-pool/core/queries/user.queries';

export interface DigitalContractQueryResult {
  digitalContract: DigitalContract;
}

export interface QueryBaseResponse {
  status: number;
  statusText: string;
}

export interface SendTenantInfoResponse {
  sendTenantInfo: DocuSignResponse;
}

export interface VerifyAesCodeQueryResponse {
  verifyAesCode: DocuSignResponse;
}

export interface AesQueryResponse {
  startAesCheck: CurrentState;
}

export interface ConfirmAesDataQueryResponse {
  confirmAesData: CurrentState;
}

export const contractAttachmentFields = `
  url
  title
  type
  identifier
  extension
  encrypted
  index
`;

export const personFields = `
  firstname
  lastname
  dateOfBirth
  gender
  email
  status
  phone
  docuSignRecipientClientId
  ${addressGQL}
`;

export const contractPropertyFields = `
  externalId
  name
  additionalCosts
  floor
  heatingCost
  bailment
  rent
  rooms
  type
  size
  customerName
  customerLogo
  titleImage {
    ${contractAttachmentFields}
  }
  ${addressGQL}
`;

export const docuSignResponse = `
  internalContractId
  embeddedUrl
  workflowState
`;

export const contractFields = `
  internalContractId
  actionRequired
  contractType
  status
  signatureType
  signedDocumentCombinedFile {
    ${contractAttachmentFields}
  }
  signedDocumentArchiveFile {
    ${contractAttachmentFields}
  }
  signedDocumentSingleFiles {
    ${contractAttachmentFields}
  }
  documentFiles {
    ${contractAttachmentFields}
  }
  landlordSigners {
    ${personFields}
  }
  tenantSigners {
    ${personFields}
  }
  property {
    ${contractPropertyFields}
  }
  continueContractWhenNotVisitedFlat
  contactInfo {
    firstname
    lastname
    email
    phone
  }
  branding {
    ${customerBrandingFields}
  }
  successRedirectUrl
`;

export const digitalContractQuery = gql`
  query digitalContract($token: String!) {
    digitalContract(token: $token) {
      ${contractFields}
    }
  }
`;

export const sendMessageQuery = gql`
  query sendMessage($token: String!) {
    sendMessage(token: $token) {
      status
      statusText
    }
  }
`;

export const sendTenantInfoMutation = gql`
  mutation sendTenantInfo($info: TenantInformationInput!) {
    sendTenantInfo(info: $info) {
      ${docuSignResponse}
    }
  }
`;
