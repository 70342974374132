import { inject, Injectable } from '@angular/core';
import { RegexTypes } from '@ui/legacy-lib';
import { ENVIRONMENT_CONFIG } from '../environment';

@Injectable()
export class UrlUtilService {
  private environment = inject(ENVIRONMENT_CONFIG);

  public getLogoRedirectUrl(url = '') {
    return RegexTypes.URL.exec(url)
      ? url
      : this.environment.property_searcher_home_url;
  }
}
