import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ServiceCardsFacade } from 'tenant-pool/core/services/cards.facade';

import { notificationConfig } from 'tenant-pool/config';
import { ShowError } from '@ui/legacy-lib';
import * as fromActions from './service-cards.actions';

@Injectable()
export class ServiceCardsEffects {
  private actions$ = inject(Actions);
  private facade = inject(ServiceCardsFacade);

  fetchCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchServiceCards),
      switchMap(() =>
        this.facade.serviceCards().pipe(
          map(serviceCards =>
            fromActions.fetchServiceCardsSuccess({ serviceCards })
          ),
          catchError(error => [
            fromActions.fetchServiceCardsFail({ error }),
            new ShowError(notificationConfig.serviceCards.sendRequest.error)
          ])
        )
      )
    )
  );

  loadMilesCardAllowed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMilesCardAllowed),
      switchMap(() =>
        this.facade.getMilesCardAllowed().pipe(
          map(isAllowed =>
            fromActions.loadMilesCardAllowedSuccess({ isAllowed })
          ),
          catchError((error: Error) => [
            fromActions.loadMilesCardAllowedError({ error }),
            new ShowError(notificationConfig.serviceCards.sendRequest.error)
          ])
        )
      )
    )
  );
}
