import { Injectable, inject } from '@angular/core';
import { SessionStorageService } from '@ui/legacy-lib';
import { SocialLogin } from '@ui/shared/models';
import { RegisterFormValues } from '../models';

const STORAGE_KEYS = {
  registerSocialLogin: 'REGISTERED_SOCIAL_LOGIN',
  registeredUserEmail: 'REGISTERED_USER_EMAIL',
  formValue: 'FORM_VALUE',
  currentStepIndex: 'CURRENT_STEP_INDEX',
  visitedSteps: 'VISITED_STEPS',
  completedSteps: 'COMPLETED_STEPS'
};

export interface StepsInfo {
  currentStepIndex: number;
  visitedSteps: string[];
  completedSteps: string[];
}

@Injectable()
export class FormStorageService {
  private storage = inject(SessionStorageService);

  public clear() {
    Object.values(STORAGE_KEYS).forEach(value =>
      this.storage.removeItem(value)
    );
  }

  public setEmail(email: string) {
    this.storage.setItem(STORAGE_KEYS.registeredUserEmail, email);
  }

  public getEmail(): string {
    return this.storage.getItem(STORAGE_KEYS.registeredUserEmail);
  }

  public setFormValue(value: RegisterFormValues) {
    this.storage.setItem(STORAGE_KEYS.formValue, value);
  }

  public getFormValue(): RegisterFormValues {
    return this.storage.getItem(STORAGE_KEYS.formValue);
  }

  public getRegisterSocialLogin(): SocialLogin {
    return this.storage.getItem(STORAGE_KEYS.registerSocialLogin);
  }

  public setRegisterSocialLogin(value: SocialLogin) {
    this.storage.setItem(STORAGE_KEYS.registerSocialLogin, value);
  }

  public setSteps(values: StepsInfo) {
    this.storage.setItem(
      STORAGE_KEYS.currentStepIndex,
      values.currentStepIndex
    );
    this.storage.setItem(STORAGE_KEYS.visitedSteps, values.visitedSteps);
    this.storage.setItem(STORAGE_KEYS.completedSteps, values.completedSteps);
  }

  public getSteps(): StepsInfo {
    const currentStepIndex = this.storage.getItem(
      STORAGE_KEYS.currentStepIndex
    );
    const visitedSteps = this.storage.getItem(STORAGE_KEYS.visitedSteps);
    const completedSteps = this.storage.getItem(STORAGE_KEYS.completedSteps);

    return {
      currentStepIndex,
      visitedSteps,
      completedSteps
    };
  }
}
