import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import { Project, SearchProfile } from '@ui/shared/models';

import * as fromActions from './search-profiles.actions';

export interface SearchProfilesState {
  searchProfiles: SearchProfile[];
  memberSearchProfiles: SearchProfile[];
  searchProfileProjectRegistrationProject: Project;
  searchProfileProjectRegistrationActionState: ActionState;

  loadSearchProfilesActionState: ActionState;
  loadMemberSearchProfilesActionState: ActionState;
  saveSearchProfileActionState: ActionState;
  deleteSearchProfileActionState: ActionState;
  deleteAllSearchProfilesActionState: ActionState;
  deactivateAllSearchProfilesActionState: ActionState;
  activateAllSearchProfilesActionState: ActionState;
}

export const initialState: SearchProfilesState = {
  searchProfiles: [],
  memberSearchProfiles: [],
  searchProfileProjectRegistrationProject: null,
  searchProfileProjectRegistrationActionState: ActionStateCreator.create(),
  loadSearchProfilesActionState: ActionStateCreator.create(),
  loadMemberSearchProfilesActionState: ActionStateCreator.create(),
  saveSearchProfileActionState: ActionStateCreator.create(),
  deleteSearchProfileActionState: ActionStateCreator.create(),
  deleteAllSearchProfilesActionState: ActionStateCreator.create(),
  deactivateAllSearchProfilesActionState: ActionStateCreator.create(),
  activateAllSearchProfilesActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.SearchProfilesAction
): SearchProfilesState {
  switch (action.type) {
    case fromActions.LOAD_SEARCH_PROFILES: {
      return {
        ...state,
        loadSearchProfilesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_SEARCH_PROFILES_SUCCESS: {
      return {
        ...state,
        loadSearchProfilesActionState: ActionStateCreator.onSuccess(),
        searchProfiles: action.searchProfiles.filter(i => !i.managedByLandlord),
        memberSearchProfiles: action.searchProfiles.filter(
          i => i.managedByLandlord
        )
      };
    }

    case fromActions.LOAD_SEARCH_PROFILES_FAIL: {
      return {
        ...state,
        loadSearchProfilesActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SAVE_SEARCH_PROFILE: {
      return {
        ...state,
        saveSearchProfileActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SAVE_SEARCH_PROFILE_SUCCESS: {
      let searchProfiles = state.searchProfiles;
      if (action.addToState)
        searchProfiles = [action.searchProfile, ...state.searchProfiles];

      return {
        ...state,
        saveSearchProfileActionState: ActionStateCreator.onSuccess(),
        searchProfiles
      };
    }

    case fromActions.SAVE_SEARCH_PROFILE_FAIL: {
      return {
        ...state,
        saveSearchProfileActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.DELETE_SEARCH_PROFILE: {
      return {
        ...state,
        deleteSearchProfileActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.DELETE_SEARCH_PROFILE_SUCCESS: {
      return {
        ...state,
        deleteSearchProfileActionState: ActionStateCreator.onSuccess(),
        searchProfiles: state.searchProfiles.filter(
          searchProfile => searchProfile.id !== action.searchProfileId
        )
      };
    }

    case fromActions.DELETE_SEARCH_PROFILE_FAIL: {
      return {
        ...state,
        deleteSearchProfileActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.DELETE_ALL_SEARCH_PROFILES: {
      return {
        ...state,
        deleteAllSearchProfilesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.DELETE_ALL_SEARCH_PROFILES_SUCCESS: {
      return {
        ...state,
        deleteAllSearchProfilesActionState: ActionStateCreator.onSuccess(),
        searchProfiles: []
      };
    }

    case fromActions.DELETE_ALL_SEARCH_PROFILES_FAIL: {
      return {
        ...state,
        deleteAllSearchProfilesActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.DEACTIVATE_ALL_SEARCH_PROFILES: {
      return {
        ...state,
        deactivateAllSearchProfilesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.DEACTIVATE_ALL_SEARCH_PROFILES_SUCCESS: {
      return {
        ...state,
        deactivateAllSearchProfilesActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.DEACTIVATE_ALL_SEARCH_PROFILES_FAIL: {
      return {
        ...state,
        deactivateAllSearchProfilesActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.ACTIVATE_ALL_SEARCH_PROFILES: {
      return {
        ...state,
        activateAllSearchProfilesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.ACTIVATE_ALL_SEARCH_PROFILES_SUCCESS: {
      return {
        ...state,
        activateAllSearchProfilesActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.ACTIVATE_ALL_SEARCH_PROFILES_FAIL: {
      return {
        ...state,
        activateAllSearchProfilesActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE: {
      return {
        ...state,
        searchProfileProjectRegistrationActionState:
          ActionStateCreator.onStart()
      };
    }

    case fromActions.FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_SUCCESS: {
      return {
        ...state,
        searchProfileProjectRegistrationProject: action.project,
        searchProfileProjectRegistrationActionState:
          ActionStateCreator.onSuccess()
      };
    }

    case fromActions.FETCH_PROJECT_FOR_PROJECT_SEARCH_PROFILE_FAIL: {
      return {
        ...state,
        searchProfileProjectRegistrationActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.UNLOAD_PROJECT_FOR_PROJECT_SEARCH_PROFILE: {
      return {
        ...state,
        searchProfileProjectRegistrationProject: null,
        searchProfileProjectRegistrationActionState:
          ActionStateCreator.onSuccess()
      };
    }

    case fromActions.TOGGLE_SEARCH_PROFILE_SUCCESS: {
      // when toggling from false to true we set the searchingSince date to today to avoid refetch
      const searchProfiles = state?.searchProfiles?.map(searchProfile => {
        if (searchProfile?.id === action?.searchProfileId) {
          return {
            ...searchProfile,
            searchingSince: searchProfile?.deactivated
              ? new Date()
              : searchProfile?.searchingSince,
            deactivated: !searchProfile?.deactivated
          };
        }
        return searchProfile;
      });

      return {
        ...state,
        searchProfiles
      };
    }

    default:
      return state;
  }
}

export const getSearchProfiles = (state: SearchProfilesState) =>
  state.searchProfiles;
export const getMemberSearchProfiles = (state: SearchProfilesState) =>
  state.memberSearchProfiles;

export const getLoadSearchProfilesActionState = (state: SearchProfilesState) =>
  state.loadSearchProfilesActionState;
export const getLoadMemberSearchProfilesActionState = (
  state: SearchProfilesState
) => state.loadMemberSearchProfilesActionState;
export const getSaveSearchProfileActionState = (state: SearchProfilesState) =>
  state.saveSearchProfileActionState;
export const getDeleteSearchProfileActionState = (state: SearchProfilesState) =>
  state.deleteSearchProfileActionState;
export const getDeleteAllSearchProfilesActionState = (
  state: SearchProfilesState
) => state.deleteSearchProfileActionState;
export const getDeactivateAllSearchProfilesActionState = (
  state: SearchProfilesState
) => state.deactivateAllSearchProfilesActionState;
export const getActivateAllSearchProfilesActionState = (
  state: SearchProfilesState
) => state.deactivateAllSearchProfilesActionState;

export const getSearchProfileProjectRegistrationProject = (
  state: SearchProfilesState
) => state.searchProfileProjectRegistrationProject;
export const getSearchProfileProjectRegistrationActionState = (
  state: SearchProfilesState
) => state.searchProfileProjectRegistrationActionState;
