import { gql } from 'apollo-angular';
import { RentDepositInfo } from '@ui/shared/models';
import { rentDepositInfoFields } from '@ui/legacy-lib';

export interface DepositInfoResponse {
  depositInfo: RentDepositInfo;
}

export interface UseRuvDepositResponse {
  useRuvDeposit: RentDepositInfo;
}

export interface UseCashDepositResponse {
  useCash: {
    status: number;
    statusText: string;
  };
}

export const depositInfoQuery = gql`
  query depositInfo($propertyId: String!) {
    depositInfo(propertyId: $propertyId) {
      ${rentDepositInfoFields}
    }
  }
`;

export const useRuvDepositMutation = gql`
  mutation useRuvDeposit($propertyId: String!, $input: RentDepositInput) {
    useRuvDeposit(propertyId: $propertyId, input: $input) {
      ${rentDepositInfoFields}
    }
  }
`;

export const useCashMutation = gql`
  mutation useCash($propertyId: String!) {
    useCash(propertyId: $propertyId) {
      status
      statusText
    }
  }
`;
