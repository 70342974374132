import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import {
  GuestApplication,
  CustomQuestionResponse,
  CustomerBranding,
  QuestionResponseContainer,
  AppointmentsBundles
} from '@ui/shared/models';

import { GuestStatus } from 'tenant-pool/models';

import * as fromActions from './guest-mode.actions';

export interface GuestModeState {
  guestAppointmentsBundles: AppointmentsBundles[];
  guestApplication: GuestApplication;
  guestCustomQuestionResponses: CustomQuestionResponse[];
  guestQuestionsContainer: QuestionResponseContainer;
  guestBranding: CustomerBranding;
  guestRegisterStatus: GuestStatus;

  guestCheckApplicationActionState: ActionState;
  guestAppointmentsActionState: ActionState;
  guestApplicationActionState: ActionState;
  guestCustomQuestionResponsesActionState: ActionState;
  guestBrandingActionState: ActionState;
  guestDeclareIntentActionState: ActionState;
  guestApplyActionState: ActionState;
  guestDeleteActionState: ActionState;
  guestRegisterActionState: ActionState;
  hierarchicalRootQuestionsActionState: ActionState;
}

export const initialState: GuestModeState = {
  guestAppointmentsBundles: null,
  guestApplication: null,
  guestCustomQuestionResponses: null,
  guestBranding: null,
  guestRegisterStatus: null,
  guestQuestionsContainer: null,

  guestCheckApplicationActionState: ActionStateCreator.create(),
  guestAppointmentsActionState: ActionStateCreator.create(),
  guestApplicationActionState: ActionStateCreator.create(),
  guestCustomQuestionResponsesActionState: ActionStateCreator.create(),
  guestBrandingActionState: ActionStateCreator.create(),
  guestDeclareIntentActionState: ActionStateCreator.create(),
  guestApplyActionState: ActionStateCreator.create(),
  guestDeleteActionState: ActionStateCreator.create(),
  guestRegisterActionState: ActionStateCreator.create(),
  hierarchicalRootQuestionsActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.guestModeActions
): GuestModeState {
  switch (action.type) {
    case fromActions.CHECK_GUEST_APPLICATION_POSSIBLE: {
      return {
        ...state,
        guestRegisterStatus: null,
        guestCheckApplicationActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.CHECK_GUEST_APPLICATION_POSSIBLE_SUCCESS: {
      return {
        ...state,
        guestRegisterStatus: action.guestStatus,
        guestCheckApplicationActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.CHECK_GUEST_APPLICATION_POSSIBLE_FAIL: {
      return {
        ...state,
        guestCheckApplicationActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.APPLY_AS_GUEST: {
      return {
        ...state,
        guestApplyActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.APPLY_AS_GUEST_SUCCESS: {
      return {
        ...state,
        guestApplyActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.APPLY_AS_GUEST_FAIL: {
      return {
        ...state,
        guestApplyActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.DELETE_GUEST: {
      return {
        ...state,
        guestDeleteActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.DELETE_GUEST_SUCCESS: {
      return {
        ...state,
        guestDeleteActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.DELETE_GUEST_FAIL: {
      return {
        ...state,
        guestDeleteActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.LOAD_GUEST_APPOINTMENTS: {
      return {
        ...state,
        guestAppointmentsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_GUEST_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        guestAppointmentsBundles: action.appointmentsBundles,
        guestAppointmentsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_GUEST_APPOINTMENTS_FAIL: {
      return {
        ...state,
        guestAppointmentsActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.LOAD_GUEST_APPLICATION: {
      return {
        ...state,
        guestApplicationActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_GUEST_APPLICATION_SUCCESS: {
      return {
        ...state,
        guestApplication: action.application,
        guestApplicationActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_GUEST_APPLICATION_FAIL: {
      const msg = action.error?.message?.replace('GraphQL error:', '').trim();
      const err = new Error(msg);
      return {
        ...state,
        guestApplicationActionState: ActionStateCreator.onError(err)
      };
    }

    case fromActions.LOAD_GUEST_CUSTOM_QUESTION_RESPONSES: {
      return {
        ...state,
        guestCustomQuestionResponsesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_SUCCESS: {
      return {
        ...state,
        guestCustomQuestionResponses: action.customQuestionResponses,
        guestCustomQuestionResponsesActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_GUEST_CUSTOM_QUESTION_RESPONSES_FAIL: {
      return {
        ...state,
        guestCustomQuestionResponsesActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.LOAD_GUEST_BRANDING: {
      return {
        ...state,
        guestBrandingActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_GUEST_BRANDING_SUCCESS: {
      return {
        ...state,
        guestBranding: action.branding,
        guestBrandingActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_GUEST_BRANDING_FAIL: {
      return {
        ...state,
        guestBrandingActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.GUEST_DECLARE_INTENT: {
      return {
        ...state,
        guestDeclareIntentActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GUEST_DECLARE_INTENT_SUCCESS: {
      return {
        ...state,
        guestDeclareIntentActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.GUEST_DECLARE_INTENT_FAIL: {
      return {
        ...state,
        guestDeclareIntentActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.GUEST_APPLICATION_REGISTER:
    case fromActions.GUEST_REGISTER: {
      return {
        ...state,
        guestRegisterActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GUEST_REGISTER_SUCCESS: {
      return {
        ...state,
        guestRegisterActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.GUEST_REGISTER_FAIL: {
      return {
        ...state,
        guestRegisterActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES: {
      return {
        ...state,
        hierarchicalRootQuestionsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_SUCCESS: {
      return {
        ...state,
        guestQuestionsContainer: action.questionContainer,
        hierarchicalRootQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES_FAIL: {
      return {
        ...state,
        hierarchicalRootQuestionsActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    default:
      return state;
  }
}

export const getGuestAppointmentsBundles = (state: GuestModeState) =>
  state.guestAppointmentsBundles;
export const getGuestApplication = (state: GuestModeState) =>
  state.guestApplication;
export const getGuestCustomQuestionResponses = (state: GuestModeState) =>
  state.guestCustomQuestionResponses;
export const getGuestQuestionContainer = (state: GuestModeState) =>
  state.guestQuestionsContainer;
export const getGuestBranding = (state: GuestModeState) => state.guestBranding;
export const getGuestRegisterStatus = (state: GuestModeState) =>
  state.guestRegisterStatus;

export const getGuestApplicationLoading = (state: GuestModeState) =>
  state.guestApplicationActionState.pending;
export const getGuestApplicationError = (state: GuestModeState) =>
  state.guestApplicationActionState.error;
export const getGuestAppointmentsLoading = (state: GuestModeState) =>
  state.guestAppointmentsActionState.pending;
export const getGuestCustomQuestionResponsesLoading = (state: GuestModeState) =>
  state.guestCustomQuestionResponsesActionState.pending;
export const getGuestBrandingLoading = (state: GuestModeState) =>
  state.guestBrandingActionState.pending;
export const getGuestDeclareIntentLoading = (state: GuestModeState) =>
  state.guestDeclareIntentActionState.pending;
export const getGuestDeclareIntentActionState = (state: GuestModeState) =>
  state.guestDeclareIntentActionState;
export const getGuestRegisterLoading = (state: GuestModeState) =>
  state.guestRegisterActionState.pending;
export const getGuestRegisterActionState = (state: GuestModeState) =>
  state.guestRegisterActionState;
export const guestCheckApplicationLoading = (state: GuestModeState) =>
  state.guestCheckApplicationActionState.pending;
export const guestApplyLoading = (state: GuestModeState) =>
  state.guestApplyActionState.pending;
export const guestApplyDone = (state: GuestModeState) =>
  state.guestApplyActionState.done;
export const guestDeleteDone = (state: GuestModeState) =>
  state.guestDeleteActionState.done;
export const guestDeleteLoading = (state: GuestModeState) =>
  state.guestDeleteActionState.pending;
// export const guestQuestionsLoading = (state: GuestModeState) =>
//   state.hierarchicalRootQuestionsActionState.pending;

export const getGuestAppointmentsLoaded = (state: GuestModeState) =>
  state.guestAppointmentsActionState.done;
