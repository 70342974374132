import { Action } from '@ngrx/store';
import { Property } from '@ui/shared/models';

export const LOAD_PROPERTY = '[Properties] Load Property';
export const LOAD_PROPERTY_SUCCESS = '[Properties] Load Property Success';
export const LOAD_PROPERTY_FAIL = '[Properties] Load Property Fail';

export class LoadProperty implements Action {
  readonly type = LOAD_PROPERTY;
  constructor(public id: string) {}
}

export class LoadPropertySuccess implements Action {
  readonly type = LOAD_PROPERTY_SUCCESS;
  constructor(public data: Property) {}
}

export class LoadPropertyFail implements Action {
  readonly type = LOAD_PROPERTY_FAIL;
  constructor(public error: Error) {}
}

export const SET_STORAGE_FOR_APPLY = '[Properties] Set Storage For Apply';
export const SET_STORAGE_FOR_APPLY_SUCCESS =
  '[Properties] Set Storage For Apply Success';

export class SetStorageForApply implements Action {
  readonly type = SET_STORAGE_FOR_APPLY;
}

export class SetStorageForApplySuccess implements Action {
  readonly type = SET_STORAGE_FOR_APPLY_SUCCESS;
}

export type PropertiesAction =
  | LoadProperty
  | LoadPropertySuccess
  | LoadPropertyFail
  | SetStorageForApply
  | SetStorageForApplySuccess;
