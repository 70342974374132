import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer
} from '@ngrx/store';

import { USER_LOGOUT_SUCCESS } from '@ui/legacy-lib';
import * as fromSystemReducer from './system/system.reducer';
import * as fromUserReducer from './user/user.reducers';
import * as fromSearchProfilesReducer from './search-profiles/search-profiles.reducers';

import * as fromAppointmentsReducer from './appointments/appointments.reducers';
import * as fromPropertiesReducer from './apply/apply.reducers';

import * as fromCustomQuestionsReducer from './custom-questions/custom-questions.reducers';
import * as fromSelfDisclosureReducer from './self-disclosure/self-disclosure.reducers';
import * as fromSchufaReducer from './schufa/schufa.reducers';
import * as fromBrandingReducer from './branding/branding.reducer';
import * as fromDigitalContractReducer from './digital-contract/digital-contract.reducers';
import * as fromGuestModeReducer from './guest-mode/guest-mode.reducers';
import * as fromPropertyMatchesReducer from './property-matches/property-matches.reducer';
import * as fromRentalDepositReducer from './rent-deposit/rent-deposit.reducer';
import * as fromMovingChecklistReducer from './moving-checklist/moving-checklist.reducer';
import * as fromMovingAuctionReducer from './moving-auction/moving-auction.reducer';
import * as fromCardsReducer from './service-cards/service-cards.reducer';
import * as fromTooltipReducer from './tooltip/tooltip.reducer';
import * as fromPaymentsReducer from './payments/payments.reducer';
import * as fromResidentReducer from './resident/resident.reducer';

export interface AppState {
  system: fromSystemReducer.SystemState;
  user: fromUserReducer.UserState;
  searchProfiles: fromSearchProfilesReducer.SearchProfilesState;
  appointments: fromAppointmentsReducer.AppointmentsState;
  properties: fromPropertiesReducer.ApplyState;
  customQuestions: fromCustomQuestionsReducer.CustomQuestionsState;
  selfDisclosure: fromSelfDisclosureReducer.SelfDisclosureState;
  schufa: fromSchufaReducer.SchufaState;
  branding: fromBrandingReducer.BrandingState;
  digitalContract: fromDigitalContractReducer.DigitalContractState;
  guestMode: fromGuestModeReducer.GuestModeState;
  propertyMatches: fromPropertyMatchesReducer.PropertyMatchesState;
  rentDeposit: fromRentalDepositReducer.RentDepositState;
  movingChecklist: fromMovingChecklistReducer.MovingChecklistState;
  movingAuction: fromMovingAuctionReducer.MovingAuctionState;
  serviceCards: fromCardsReducer.ServiceCardsState;
  tooltip: fromTooltipReducer.TooltipState;
  payments: fromPaymentsReducer.PaymentsState;
  resident: fromResidentReducer.ResidentState;
}

export const appReducers: ActionReducerMap<AppState> = {
  system: fromSystemReducer.systemReducer,
  user: fromUserReducer.reducer,
  searchProfiles: fromSearchProfilesReducer.reducer,
  appointments: fromAppointmentsReducer.reducer,
  properties: fromPropertiesReducer.reducer,
  customQuestions: fromCustomQuestionsReducer.reducer,
  selfDisclosure: fromSelfDisclosureReducer.reducer,
  schufa: fromSchufaReducer.reducer,
  branding: fromBrandingReducer.reducer,
  digitalContract: fromDigitalContractReducer.reducer,
  guestMode: fromGuestModeReducer.reducer,
  propertyMatches: fromPropertyMatchesReducer.propertyMatchesReducer,
  rentDeposit: fromRentalDepositReducer.rentDepositReducer,
  movingChecklist: fromMovingChecklistReducer.movingChecklistReducer,
  movingAuction: fromMovingAuctionReducer.movingAuctionReducer,
  serviceCards: fromCardsReducer.reducer,
  tooltip: fromTooltipReducer.reducer,
  payments: fromPaymentsReducer.reducer,
  resident: fromResidentReducer.reducer
};

export function logout(reducer: ActionReducer<AppState>) {
  return function (state: AppState, action: Action) {
    return reducer(
      action.type === USER_LOGOUT_SUCCESS ? undefined : state,
      action
    );
  };
}

export const metaReducers: MetaReducer<AppState>[] = [logout];

export const getUserState =
  createFeatureSelector<fromUserReducer.UserState>('user');
export const getSearchProfilesState =
  createFeatureSelector<fromSearchProfilesReducer.SearchProfilesState>(
    'searchProfiles'
  );
export const getAppointmentsState =
  createFeatureSelector<fromAppointmentsReducer.AppointmentsState>(
    'appointments'
  );
export const getPropertiesState =
  createFeatureSelector<fromPropertiesReducer.ApplyState>('properties');
export const getCustomQuestionsState =
  createFeatureSelector<fromCustomQuestionsReducer.CustomQuestionsState>(
    'customQuestions'
  );
export const getSelfDisclosureState =
  createFeatureSelector<fromSelfDisclosureReducer.SelfDisclosureState>(
    'selfDisclosure'
  );
export const getSchufaState =
  createFeatureSelector<fromSchufaReducer.SchufaState>('schufa');
export const getBrandingState =
  createFeatureSelector<fromBrandingReducer.BrandingState>('branding');
export const getDigitalContractState =
  createFeatureSelector<fromDigitalContractReducer.DigitalContractState>(
    'digitalContract'
  );
export const getGuestModeState =
  createFeatureSelector<fromGuestModeReducer.GuestModeState>('guestMode');
export const getPropertyMatchesState =
  createFeatureSelector<fromPropertyMatchesReducer.PropertyMatchesState>(
    'propertyMatches'
  );
export const getRentDepositState =
  createFeatureSelector<fromRentalDepositReducer.RentDepositState>(
    'rentDeposit'
  );
export const getSystemState =
  createFeatureSelector<fromSystemReducer.SystemState>('system');

export const getMovingChecklistState =
  createFeatureSelector<fromMovingChecklistReducer.MovingChecklistState>(
    'movingChecklist'
  );

export const getMovingAuctionState =
  createFeatureSelector<fromMovingAuctionReducer.MovingAuctionState>(
    'movingAuction'
  );

export const getServiceCardsState =
  createFeatureSelector<fromCardsReducer.ServiceCardsState>('serviceCards');

export const getTooltipState =
  createFeatureSelector<fromTooltipReducer.TooltipState>('tooltip');

export const getPaymentsState =
  createFeatureSelector<fromPaymentsReducer.PaymentsState>('payments');

export const getResidentState =
  createFeatureSelector<fromResidentReducer.ResidentState>('resident');
