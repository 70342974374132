import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { SCREEN_MEDIUM_MIN } from '@ui/legacy-lib';
import { BaseStep } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-contract-signing',
  templateUrl: './contract-signing.component.html',
  styleUrls: ['./contract-signing.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class ContractSigningComponent
  extends BaseStep
  implements OnInit, OnDestroy
{
  @Input() isSigned = false;
  @Input() hasError = false;
  @Input() showShopCard = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTrackRelocationShop = new EventEmitter();

  public get narrowScreen() {
    return window.innerWidth < SCREEN_MEDIUM_MIN;
  }

  public trackRelocationShop() {
    this.onTrackRelocationShop.emit();
  }
}
