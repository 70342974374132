import { createReducer, on } from '@ngrx/store';
import {
  Tooltip,
  TooltipProgress,
  TooltipTour,
  TooltipTourElement
} from '@ui/shared/models';
import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './tooltip.actions';

export interface TooltipState {
  tooltips: Tooltip[];
  tooltipTours: TooltipTour[];
  pathTooltipsMap: Map<string, Tooltip[]>;
  pathTooltipToursMap: Map<string, TooltipTour[]>;
  combinedPathTooltipTourMap: Map<string, TooltipTour[]>;
  tooltipsActionState: ActionState;
  tooltipToursActionState: ActionState;
  setTooltipsSeenActionState: ActionState;
  tooltipProgress: TooltipProgress;
  tooltipsSeen: number[];
}

export const initialState: TooltipState = {
  tooltips: [],
  tooltipTours: [],
  pathTooltipsMap: new Map<string, Tooltip[]>(),
  pathTooltipToursMap: new Map<string, TooltipTour[]>(),
  combinedPathTooltipTourMap: new Map<string, TooltipTour[]>(),
  tooltipsActionState: ActionStateCreator.create(),
  tooltipToursActionState: ActionStateCreator.create(),
  setTooltipsSeenActionState: ActionStateCreator.create(),
  tooltipProgress: {
    currentTour: null,
    currentTourElement: null
  },
  tooltipsSeen: []
};

function getNewCombinedMap(
  state: TooltipState,
  path: string,
  combinedTooltipTour: TooltipTour[]
) {
  return new Map<string, TooltipTour[]>(state.combinedPathTooltipTourMap).set(
    path,
    combinedTooltipTour
  );
}

function createCombinedTooltipTour(
  pathTooltipsMap: Map<string, Tooltip[]>,
  pathTooltipToursMap: Map<string, TooltipTour[]>,
  path: string
): TooltipTour[] {
  const pageTooltips = pathTooltipsMap.has(path)
    ? pathTooltipsMap.get(path)
    : [];
  const pageTooltipTours = pathTooltipToursMap.has(path)
    ? pathTooltipToursMap.get(path)
    : [];

  // wrap single tooltips on page into one combined tooltip tour
  const tourElements: TooltipTourElement[] = pageTooltips.map(
    (tooltip, index) => ({
      id: -1,
      tooltip,
      step: index
    })
  );

  const createdTooltipTour: TooltipTour = {
    id: -1,
    tourElements
  };

  return [...pageTooltipTours, createdTooltipTour];
}

function createContinuedTooltipProgress(
  currentProgress: TooltipProgress,
  tours: TooltipTour[]
): TooltipProgress {
  const newProgress: TooltipProgress = {
    currentTour: null,
    currentTourElement: null
  };

  const currentTourIndex = tours.findIndex(
    tour => tour.id === currentProgress.currentTour.id
  );
  const currentElementIndex =
    currentProgress.currentTour.tourElements.findIndex(
      element => element.id === currentProgress.currentTourElement.id
    );

  // find next element
  if (
    currentElementIndex + 1 <
    currentProgress.currentTour.tourElements.length
  ) {
    newProgress.currentTour = currentProgress.currentTour;
    newProgress.currentTourElement =
      currentProgress.currentTour.tourElements[currentElementIndex + 1];
  } else if (currentTourIndex + 1 < tours.length) {
    newProgress.currentTour = tours[currentTourIndex + 1];
    newProgress.currentTourElement = newProgress.currentTour.tourElements[0];
  }

  return newProgress;
}

function initTooltipProgress(
  tours: TooltipTour[],
  idsSeen: number[]
): TooltipProgress {
  const firstTour = tours.length > 0 ? tours[0] : null;
  const firstTourElement =
    firstTour?.tourElements?.length > 0 ? firstTour.tourElements[0] : null;

  let newProgress: TooltipProgress = {
    currentTour: firstTour,
    currentTourElement: firstTourElement
  };

  while (idsSeen.includes(newProgress.currentTourElement?.tooltip?.id)) {
    newProgress = createContinuedTooltipProgress(newProgress, tours);
  }

  return newProgress;
}

export const reducer = createReducer(
  initialState,

  on(fromActions.fetchTooltipsUnseen, state => ({
    ...state,
    tooltipsActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.fetchTooltipsUnseenSuccess, (state, { path, tooltips }) => {
    const newMap = new Map<string, Tooltip[]>(state.pathTooltipsMap);
    newMap.set(path, tooltips);

    const combinedTooltipTour = createCombinedTooltipTour(
      newMap,
      state.pathTooltipToursMap,
      path
    );

    return {
      ...state,
      tooltipsActionState: ActionStateCreator.onSuccess(),
      tooltips: tooltips,
      pathTooltipsMap: newMap,
      combinedPathTooltipTourMap: getNewCombinedMap(
        state,
        path,
        combinedTooltipTour
      ),
      tooltipProgress: initTooltipProgress(
        combinedTooltipTour,
        state.tooltipsSeen
      )
    };
  }),

  on(fromActions.fetchTooltipsUnseenFail, (state, { error }) => ({
    ...state,
    tooltipsActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.fetchTooltipToursUnseen, state => ({
    ...state,
    tooltipToursActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.fetchTooltipToursUnseenSuccess,
    (state, { path, tooltipTours }) => {
      const newMap = new Map<string, TooltipTour[]>(state.pathTooltipToursMap);
      newMap.set(path, tooltipTours);

      const combinedTooltipTour = createCombinedTooltipTour(
        state.pathTooltipsMap,
        newMap,
        path
      );

      return {
        ...state,
        tooltipToursActionState: ActionStateCreator.onSuccess(),
        tooltipTours: tooltipTours,
        pathTooltipToursMap: newMap,
        combinedPathTooltipTourMap: getNewCombinedMap(
          state,
          path,
          combinedTooltipTour
        ),
        tooltipProgress: initTooltipProgress(
          combinedTooltipTour,
          state.tooltipsSeen
        )
      };
    }
  ),

  on(fromActions.fetchTooltipToursUnseenFail, (state, { error }) => ({
    ...state,
    tooltipToursActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.continueTooltipProgress, state => {
    const currentPath =
      state.tooltipProgress.currentTourElement.tooltip.anchorPoint.path;
    const tours = state.pathTooltipToursMap.get(currentPath);

    let newProgress: TooltipProgress = createContinuedTooltipProgress(
      state.tooltipProgress,
      tours
    );

    while (
      state.tooltipsSeen.includes(newProgress.currentTourElement?.tooltip?.id)
    ) {
      newProgress = createContinuedTooltipProgress(newProgress, tours);
    }

    return {
      ...state,
      tooltipProgress: newProgress
    };
  }),

  on(fromActions.resetTooltipProgress, state => ({
    ...state,
    tooltipProgress: {
      currentTour: null,
      currentTourElement: null
    }
  })),

  on(fromActions.setTooltipsSeen, state => ({
    ...state,
    setTooltipsSeenActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.setTooltipsSeenSuccess, (state, { ids }) => ({
    ...state,
    setTooltipsSeenActionState: ActionStateCreator.onSuccess(),
    tooltipsSeen: [...state.tooltipsSeen, ...ids]
  })),

  on(fromActions.setTooltipTourSeen, state => ({
    ...state,
    setTooltipsSeenActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.setTooltipTourSeenSuccess, (state, { ids }) => ({
    ...state,
    setTooltipsSeenActionState: ActionStateCreator.onSuccess(),
    tooltipsSeen: [...state.tooltipsSeen, ...ids]
  })),

  on(
    fromActions.setTooltipsSeenFail,
    fromActions.setTooltipTourSeenFail,
    (state, { error, ids }) => ({
      ...state,
      setTooltipsSeenActionState: ActionStateCreator.onError(error),
      tooltipsSeen: [...state.tooltipsSeen, ...ids] // remove the tooltip from the front-end
    })
  )
);

export const getTooltips = (state: TooltipState) => state.tooltips;
export const getTooltipTours = (state: TooltipState) => state.tooltipTours;
export const getPathTooltipsMap = (state: TooltipState) =>
  state.pathTooltipsMap;
export const getPathTooltipToursMap = (state: TooltipState) =>
  state.pathTooltipToursMap;
export const getCombinedPathTooltipTourMap = (state: TooltipState) =>
  state.combinedPathTooltipTourMap;
export const getTooltipProgress = (state: TooltipState) =>
  state.tooltipProgress;
