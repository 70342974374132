import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {
  DigitalContractItpState,
  SchufaVerificationState
} from '@ui/shared/models';
import { BaseStep } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-contract-id-verification',
  templateUrl: './contract-id-verification.component.html',
  styleUrls: ['./contract-id-verification.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ContractIdVerificationComponent
  extends BaseStep
  implements OnInit
{
  public maxBirthDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month(),
    day: moment().date()
  };

  public minBirthDate: NgbDateStruct = {
    year: moment().year() - 120,
    month: moment().month(),
    day: moment().date()
  };

  ngOnInit(): void {
    super.ngOnInit();
  }

  public get isRestart() {
    return (
      this.aesSchufaState ===
        SchufaVerificationState.DATA_NOT_CORRECT_NEEDS_TO_BE_RESTARTED ||
      this.aesSchufaState ===
        SchufaVerificationState.DATA_NOT_CORRECT_AFTER_CONFIRMATION
    );
  }

  public get isCancel() {
    return this.aesSchufaState === SchufaVerificationState.CANCEL;
  }

  public get isErrorMaxTries() {
    return (
      this.aesSchufaState === SchufaVerificationState.MAX_SCHUFA_TRIES_EXCEEDED
    );
  }

  public get isError() {
    return this.aesSchufaState === SchufaVerificationState.ERROR;
  }

  public get isItpError() {
    return (
      this.aesItpState === DigitalContractItpState.FAILED ||
      this.aesItpState === DigitalContractItpState.TECHNICAL_ERROR ||
      this.aesItpState === DigitalContractItpState.UNKNOWN
    );
  }

  private get aesSchufaState() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('aesSchufaState').value;
  }

  private get aesItpState() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('aesItpState').value;
  }
}
