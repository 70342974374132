import { Attachment } from '@ui/shared/models';
import {
  SchufaDeliveryResponseData,
  SchufaOrderResponseData
} from 'tenant-pool/core/queries';

export interface SchufaPersonalInformation {
  personalData?: SchufaPersonalInformationData;
  approvalTermsOfCondition?: boolean;
}

export interface SendSchufaPayment {
  sepaLev?: SchufaSepa;
}

export interface SendSchufaDelivery {
  identityData?: SchufaIdentityData;
  postDelivery?: boolean;
}

export interface SchufaFormValues {
  personalInformation?: SchufaPersonalInformation;
  payment?: SendSchufaPayment;
  order?: SendSchufaOrder;
  delivery?: SendSchufaDelivery;
  finish?: SchufaFinish;
}

export interface SendSchufaOrder {
  orderWithCost?: boolean;
}

export interface SchufaFinish {
  creditReport?: Attachment;
}

export interface SchufaAddress {
  country?: string;
  zipCode?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
}

export interface SchufaPersonalInformationData {
  salutation?: string;
  lastName?: string;
  firstName?: string;
  placeOfBirth?: string;
  dateOfBirth?: Date;
  address?: SchufaAddress;
  preAddress?: SchufaAddress[];
  email?: string;
}

export interface SchufaIdentityData {
  iBAN?: string;
  idCardOld?: boolean;
  idCardSerialNumber?: string;
  idCardBirthDate?: string;
  idCardExpireDate?: string;
  idCardCheckSum?: string;
}

export interface SchufaSepa {
  bankAccountOwner?: string;
  iban?: string;
  approvalSEPA?: boolean;
}

export interface SchufaNestedError {
  extra?: SchufaOrderResponseData | SchufaDeliveryResponseData;
}

export interface SchufaErrorStructure {
  graphQLErrors?: SchufaNestedError[];
  errors?: SchufaNestedError[];

  [key: string]: SchufaNestedError[];
}

export enum SchufaSalutation {
  HERR = 'HERR',
  FRAU = 'FRAU'
}

export enum SchufaDeliveryOption {
  DOWNLOAD = 'DOWNLOAD',
  POST = 'POST'
}
