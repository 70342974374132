import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import {
  CheckTranslations,
  getTranslationLoaded,
  getTranslationLoadedInformal,
  I18nService,
  INFRASTRUCTURE_CONFIG,
  LocalStorageService,
  SetAvailableLanguages,
  SetCurrentLocale,
  SetDefaultLanguageCode
} from '@ui/legacy-lib';
import * as fromAppState from 'tenant-pool/+state';

@Injectable()
export class TenantTranslationsGuard {
  private store = inject(Store);
  private storage = inject(LocalStorageService);
  private i18nService = inject(I18nService);
  private config = inject(INFRASTRUCTURE_CONFIG);

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(getTranslationLoaded),
      this.store.select(getTranslationLoadedInformal),
      this.store.select(fromAppState.getUserData)
    ]).pipe(
      filter(([, , userData]) => !!userData),
      map(([loaded, loadedInformal, userData]) => {
        const informal =
          userData?.customerBranding?.itpSettings?.informalLanguage ?? false;

        if ((!loaded && !informal) || (!loadedInformal && informal)) {
          const localeKey = this.config.storageKeys.localeKey;
          const currentLocale = this.storage.getItem(localeKey);
          const appName = this.config.environment.app_name;
          const languageConfig =
            this.i18nService.getInfrastructureLanguageConfig();

          this.store.dispatch(
            new CheckTranslations(appName, currentLocale, informal)
          );
          this.store.dispatch(new SetCurrentLocale(currentLocale));
          this.store.dispatch(
            new SetAvailableLanguages(languageConfig.availableLanguages)
          );
          this.store.dispatch(
            new SetDefaultLanguageCode(languageConfig.defaultLanguageCode)
          );
        }

        return (loaded && !informal) || (loadedInformal && informal);
      }),
      filter(languageLoaded => languageLoaded),
      take(1),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
