import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RentDepositModel } from '@ui/shared/models';
import { AuthTokenService } from '@ui/legacy-lib';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core/environment/environment-config.token';

@Injectable()
export class ProtocolPdfService {
  private http = inject(HttpClient);
  private authTokenService = inject(AuthTokenService);
  private env = inject(ENVIRONMENT_CONFIG);

  public getProtocolFile(payload: RentDepositModel) {
    const token = this.authTokenService.getToken().access_token;
    const PATH = `${this.env.graphql_host}/protocol-pdf`;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.http.request('POST', PATH, {
      headers,
      responseType: 'blob',
      body: { payload, token }
    });
  }
}
