import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take, withLatestFrom, map, tap } from 'rxjs/operators';

import * as fromAppState from 'tenant-pool/+state';

@Injectable()
export class NewHomeResolver {
  private store = inject(Store);

  resolve() {
    return this.store
      .select(fromAppState.getPropertyMatchesRentedActionState)
      .pipe(
        filter(state => !state.pending),
        take(1),
        withLatestFrom(
          this.store.select(fromAppState.getPropertyMatchesRented)
        ),
        map(([_, rentedObjects]) => rentedObjects?.length > 0),
        tap(isMyNewHome =>
          this.store.dispatch(fromAppState.SaveMyNewHomeState({ isMyNewHome }))
        )
      );
  }
}
