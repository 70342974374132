import {
  CustomQuestion,
  RootQuestion,
  RootQuestionContainer
} from '@ui/shared/models';
import { ActionStateCreator, ActionState } from '@ui/legacy-lib';

import * as fromActions from './custom-questions.actions';

export interface CustomQuestionsState {
  customQuestions: CustomQuestion[];
  hierarchicalRootQuestions: RootQuestion[];
  customQuestionsActionState: ActionState;
  saveAnswersActionState: ActionState;
  isModalOpen: boolean;
  customerQuestions: RootQuestionContainer[];
}

export const initialState: CustomQuestionsState = {
  customQuestions: null,
  hierarchicalRootQuestions: null,
  customQuestionsActionState: ActionStateCreator.create(),
  saveAnswersActionState: ActionStateCreator.create(),
  isModalOpen: false,
  customerQuestions: null
};

export function reducer(
  state = initialState,
  action: fromActions.CustomQuestionsAction
): CustomQuestionsState {
  switch (action.type) {
    case fromActions.SAVE_CUSTOM_QUESTIONS_ANSWERS: {
      return {
        ...state,
        saveAnswersActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SAVE_CUSTOM_QUESTIONS_ANSWERS_SUCCESS: {
      return {
        ...state,
        saveAnswersActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SAVE_CUSTOM_QUESTIONS_ANSWERS_FAIL: {
      return {
        ...state,
        saveAnswersActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER:
    case fromActions.LOAD_CUSTOM_QUESTIONS:
    case fromActions.LOAD_QUESTIONS_BY_CUSTOMER:
    case fromActions.HIERARCHICAL_ROOT_QUESTION_RESPONSE: {
      return {
        ...state,
        customQuestionsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_CUSTOM_QUESTIONS_SUCCESS: {
      return {
        ...state,
        customQuestions: action.customQuestions,
        customQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_HIERARCHICAL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        hierarchicalRootQuestions: action.hierarchicalRootQuestions.map(
          q => q.rootQuestion
        ),
        customQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.HIERARCHICAL_ROOT_QUESTION_RESPONSE_SUCCESS: {
      return {
        ...state,
        customQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_CUSTOM_QUESTIONS_FAIL:
    case fromActions.HIERARCHICAL_ROOT_QUESTION_RESPONSE_FAIL: {
      return {
        ...state,
        customQuestionsActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SET_HIERARCHICAL_QUESTIONS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.isOpen
      };
    }

    case fromActions.LOAD_QUESTIONS_AND_ANSWERS_BY_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerQuestions: action.customerQuestions,
        customQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }

    default:
      return state;
  }
}

export const getSaveAnswersActionState = (state: CustomQuestionsState) =>
  state.saveAnswersActionState;
export const getCustomQuestionActionState = (state: CustomQuestionsState) =>
  state.customQuestionsActionState;
export const getCustomQuestions = (state: CustomQuestionsState) =>
  state.customQuestions;
export const getHierarchicalQuestions = (state: CustomQuestionsState) =>
  state.hierarchicalRootQuestions;
export const isHierarchicalQuestionsModalOpen = (state: CustomQuestionsState) =>
  state.isModalOpen;
export const getCustomerQuestionAndAnswers = (state: CustomQuestionsState) =>
  state.customerQuestions;
export const isCustomQuestionLoading = (state: CustomQuestionsState) =>
  state.customQuestionsActionState.pending &&
  !state.customQuestionsActionState.done;
