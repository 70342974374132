import { gql } from 'apollo-angular';
import {
  GuestApplication,
  CustomQuestionResponse,
  CustomerBranding,
  GuestUserProfile,
  QuestionResponseContainer,
  AppointmentsBundles
} from '@ui/shared/models';
import { appointmentFields } from '@ui/legacy-lib';
import { questionResponseContainerFields } from '@ui/legacy-lib';
import { basicPropertySearcherFields } from 'tenant-pool/core/queries/property-searcher.queries';
import { GuestStatus } from 'tenant-pool/models';
import { propertyPsSide } from '@ui/legacy-lib';
import { customerBrandingFields } from 'tenant-pool/core/queries/user.queries';

export interface CheckGuestApplicationExistsQueryResult {
  checkGuestApplication: GuestStatus;
}

export interface GuestAppointmentsQueryResult {
  guestAppointments: AppointmentsBundles[];
}

export interface GuestApplicationQueryResult {
  guestApplication: GuestApplication;
}

export interface CustomQuestionResponsesQueryResult {
  customQuestionResponses: CustomQuestionResponse[];
}

export interface BrandingQueryResult {
  branding: CustomerBranding;
}

export interface GuestMutationResult {
  status: number;
  statusText: string;
}

export interface GuestRegister {
  userProfile: GuestUserProfile;
  access_token: string;
  session_state: string;
}

export interface GuestRegisterResponse {
  register: GuestRegister;
}

export interface GuestApplicationRegisterResponse {
  applicationRegister: GuestRegister;
}

export interface GuestQuestionsQueryResponse {
  guestQuestions: QuestionResponseContainer;
}

export const responseStatusFields = `
  status
  statusText
`;

export const attachmentFields = `
  url
  title
  type
  identifier
  extension
  encrypted
  index
`;

export const checkGuestApplicationQuery = gql`
  query checkGuestApplication($email: String!, $propertyId: String!) {
    checkGuestApplication(email: $email, propertyId: $propertyId) {
      applicationPossible
      alreadyRegistered
      alreadyGuest
      token
    }
  }
`;

export const guestAppointmentsQuery = gql`
  query guestAppointments($token: String!) {
    guestAppointments(token: $token) {
      ${appointmentFields}
    }
  }
`;

export const guestApplicationQuery = gql`
  query guestApplication($token: String!) {
    guestApplication(token: $token) {
      askForViewingAppointmentAttendance
      userProfile
      applicantStatus
      ${basicPropertySearcherFields}
      ${propertyPsSide}
    }
  }
`;

export const brandingQuery = gql`
  query branding($token: String!) {
    branding(token: $token) {
      ${customerBrandingFields}
    }
  }
`;

export const customQuestionResponsesQuery = gql`
  query customQuestionResponses($token: String!) {
    customQuestionResponses(token: $token) {
      responses
      selectedRange
      comment
      customQuestion {
        key
        type
        subType
        title
        options
        commentHint
      }
    }
  }
`;

export const applyAsGuestMutation = gql`
  mutation applyAsGuest($guestData: GuestDataInput, $token: String!) {
    applyAsGuest(guestData: $guestData, token: $token) {
      ${responseStatusFields}
    }
  }
`;

export const appointmentAcceptancesCancelMutation = gql`
  mutation appointmentAcceptancesCancel($cancelAppointmentData: CancelAppointmentDataInput, $token: String!) {
    appointmentAcceptancesCancel(cancelAppointmentDataInput: $cancelAppointmentData, token: $token) {
      ${responseStatusFields}
    }
  }
`;

export const registerMutation = gql`
  mutation register($userData: TenantRegisterInput!, $token: String!) {
    register(input: $userData, token: $token) {
      access_token
      session_state
    }
  }
`;

export const applicationRegisterMutation = gql`
  mutation applicationRegister(
    $userData: GuestRegisterInput!
    $token: String!
  ) {
    applicationRegister(input: $userData, token: $token) {
      access_token
      session_state
    }
  }
`;

export const deleteQuery = gql`
  mutation delete($reasonData: ReasonInput, $token: String!) {
    delete(input: $reasonData, token: $token) {
      ${responseStatusFields}
    }
  }
`;

export const acceptAppointmentMutation = gql`
  mutation acceptAppointment($id: String!, $token: String!) {
    acceptAppointment(id: $id, token: $token) {
      ${responseStatusFields}
    }
  }
`;

export const declareIntentMutation = gql`
  mutation guestDeclareIntent($declareIntentData: DeclareIntentInput!, $token: String!) {
    guestDeclareIntent(input: $declareIntentData, token: $token) {
      ${responseStatusFields}
    }
  }
`;

export const guestQuestionsQuery = gql`
  query guestQuestions($token: String!) {
    guestQuestions(token: $token) {
      ${questionResponseContainerFields}
    }
  }
`;
