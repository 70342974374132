import { Injectable, inject } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';

import { filter, map, switchMap } from 'rxjs/operators';

import { zip } from 'rxjs';

import {
  PropertySearcherDocumentTypes,
  SelfDisclosureModel
} from '@ui/shared/models';

import { FileUploadService } from '@ui/legacy-lib';
import { SelfDisclosureService } from '@ui/legacy-lib';

import { cloneDeep } from '@apollo/client/utilities';
import {
  saveSelfDisclosureResponsesMutation,
  selfDisclosureQuery,
  selfDisclosureResponses,
  SelfDisclosureQueryResponse,
  SelfDisclosureAnswerResponse,
  SelfDisclosureSaveResponse
} from '../queries';

@Injectable()
export class SelfDisclosureFacade {
  private apollo = inject(Apollo);
  private fileUploadService = inject(FileUploadService);
  private selfDisclosureService = inject(SelfDisclosureService);

  private selfDisclosureQuery: QueryRef<SelfDisclosureQueryResponse>;
  private selfDisclosureAnswers: QueryRef<SelfDisclosureAnswerResponse>;

  public loadSelfDisclosureModel(id: string) {
    this.selfDisclosureQuery =
      this.apollo.watchQuery<SelfDisclosureQueryResponse>({
        query: selfDisclosureQuery,
        variables: { id },
        fetchPolicy: 'no-cache'
      });

    return this.selfDisclosureQuery.valueChanges.pipe(
      filter(response => !response.loading || !!response.data),
      map(response => response.data)
    );
  }

  public saveAnswers(id: string, payload: SelfDisclosureModel) {
    const clonedPayload = cloneDeep(payload);
    const documents = payload.uploadedDocuments || [];

    const documentsToUpload = documents.filter(
      attachment => attachment.file instanceof Blob
    );
    const nestedDocumentsToUpload =
      this.selfDisclosureService.getDocumentsForUpload(clonedPayload.questions);

    return zip(
      this.fileUploadService.uploadDocuments(
        documentsToUpload,
        PropertySearcherDocumentTypes.SHARED_DOCUMENT
      ),
      this.fileUploadService.uploadDocuments(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        nestedDocumentsToUpload.map(element => element.file),
        PropertySearcherDocumentTypes.SHARED_DOCUMENT
      )
    ).pipe(
      map(([uploadedDocuments, nestedUploadedDocuments]) => {
        const documentsToSave = [...documents, ...uploadedDocuments].filter(
          attachment => attachment && !(attachment.file instanceof Blob)
        );

        // replace file-objects with attachment received from filer (upload call)
        nestedUploadedDocuments.forEach(
          (upload, index) =>
            (nestedDocumentsToUpload[index].parentObject.upload = upload)
        );

        return {
          ...clonedPayload,
          uploadedDocuments: [...documentsToSave]
        };
      }),
      switchMap(input =>
        this.apollo.mutate<SelfDisclosureSaveResponse>({
          mutation: saveSelfDisclosureResponsesMutation,
          variables: { id, input }
        })
      )
    );
  }

  public getAnswers(id: string) {
    this.selfDisclosureAnswers =
      this.apollo.watchQuery<SelfDisclosureAnswerResponse>({
        query: selfDisclosureResponses,
        variables: { id },
        fetchPolicy: 'no-cache'
      });

    return this.selfDisclosureAnswers.valueChanges.pipe(
      filter(
        response =>
          !response.loading ||
          !!response.data ||
          !!response.data.getSelfDisclosureResponses
      ),
      map(
        response =>
          response?.data?.getSelfDisclosureResponses?.data?.answers || {}
      )
    );
  }

  public refetch(): void {
    void this.selfDisclosureAnswers.refetch();
  }
}
