import { createReducer, on } from '@ngrx/store';
import { MovingAuctionResponse } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './moving-auction.actions';

export interface MovingAuctionState {
  currentStepNumber: number;
  sendRequestActionState: ActionState;
  movingAuctionResponse: MovingAuctionResponse;
  redirect: string[];
}

export const initialState: MovingAuctionState = {
  currentStepNumber: 1,
  sendRequestActionState: ActionStateCreator.create(),
  movingAuctionResponse: null,
  redirect: null
};

export const movingAuctionReducer = createReducer(
  initialState,
  on(fromActions.MovingAuctionWizardNextStep, state => {
    const { currentStepNumber } = state;
    return {
      ...state,
      currentStepNumber: currentStepNumber + 1
    };
  }),
  on(fromActions.MovingAuctionWizardPreviousStep, state => {
    const { currentStepNumber } = state;
    return {
      ...state,
      currentStepNumber: currentStepNumber > 1 ? currentStepNumber - 1 : 1
    };
  }),
  on(fromActions.MovingAuctionWizardGoToStep, (state, { stepNumber }) => {
    return {
      ...state,
      currentStepNumber: stepNumber
    };
  }),
  on(fromActions.MovingAuctionWizardSetRedirect, (state, { redirect }) => {
    return {
      ...state,
      redirect
    };
  }),
  on(fromActions.MovingAuctionWizardReset, () => {
    return {
      ...initialState
    };
  }),
  on(fromActions.MovingAuctionSendRequest, state => {
    return {
      ...state,
      sendRequestActionState: ActionStateCreator.onStart()
    };
  }),
  on(
    fromActions.MovingAuctionSendRequestSuccess,
    (state, { movingAuctionResponse }) => {
      return {
        ...state,
        movingAuctionResponse,
        sendRequestActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.MovingAuctionSendRequestFail, (state, { error }) => {
    return {
      ...state,
      movingAuctionResponse: {
        httpError: error
      },
      sendRequestActionState: ActionStateCreator.onError(error)
    };
  })
);

export const getCurrentMovingAuctionWizardStep = (state: MovingAuctionState) =>
  state.currentStepNumber;
export const getMovingAuctionResponse = (state: MovingAuctionState) =>
  state.movingAuctionResponse;
export const getSendRequestActionState = (state: MovingAuctionState) =>
  state.sendRequestActionState;
export const getRedirect = (state: MovingAuctionState) => state.redirect;
