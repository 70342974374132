export const notificationConfig = {
  property: {
    fetch: {
      error: 'property.list_load_error_m'
    },
    load: {
      error: 'property.load_error_m'
    },
    remove: {
      error: 'property.remove_error_m',
      success: 'property.remove_success_m'
    },
    accept: {
      error: 'property.accept_error_m',
      success: 'property.accept_success_m'
    },
    clipboard_copy: 'property.link_copied_m'
  },
  propertyApplyProcessPSBeans: {
    close: {
      error: 'property.close_error_m',
      success: 'property.close_success_m'
    },
    load: {
      error: 'propertyApplyProcessPSBeans.load_error_m'
    }
  },
  appointment: {
    load: {
      error: 'appointment.list_load_error_m'
    },
    select: {
      success: 'appointment.select_success_m',
      error: 'appointment.select_error_m'
    },
    cancel: {
      success: 'appointment.cancel_success_m',
      error: 'appointment.cancel_error_m'
    },
    cancelAll: {
      success: 'appointment.cancel_all_success_m',
      error: 'appointment.cancel_all_error_m'
    }
  },
  application: {
    create: {
      error: 'application.create_error_m'
    },
    apply: {
      success: 'application.apply_success_m',
      error: 'application.apply_error_m'
    },
    confirmIntent: {
      profileIncomplete: 'application.confirm_intent_profile_incomplete_message'
    },
    denyIntent: {
      success: 'application.deny_intent_success_m',
      error: 'application.deny_intent_error_m'
    },
    declareIntent: {
      success: 'application.declare_intent_success_m',
      error: 'application.declare_intent_error_m'
    },
    identityCheck: {
      error: 'application.identity_check_error_m'
    }
  },
  user: {
    save: {
      error: 'user.save_error_m',
      success: 'user.save_success_m'
    },
    register: {
      error: 'UNKNOWN_SERVER_ERROR_M'
    },
    changePassword: {
      error: 'user.change_password_error_m',
      success: 'user.change_password_success_m'
    },
    changeEmail: {
      error: 'user.change_email_request_error_m',
      success: 'user.change_email_request_success_m'
    },
    confirmEmail: {
      error: 'user.confirm_email_error_m',
      success: 'user.confirm_email_success_m'
    },
    verifyEmail: {
      error: 'user.verify_email_error_m'
    },
    setSearchingStatus: {
      error: 'user.set_searching_status_error_m'
    },
    unlockAccount: {
      error: 'user.unlock_account_error_m',
      success: 'user.unlock_account_success_m'
    },
    LeaveInternalTenantPool: {
      error: 'user.LEAVE_INTERNAL_TENANT_POOL_error_m',
      success: 'user.LEAVE_INTERNAL_TENANT_POOL_success_m'
    },
    changePreferredLanguage: {
      error: 'user.change_preferred_language_error_m',
      success: 'user.change_preferred_language_success_m',
      reloading: 'user.change_preferred_language_reloading_m'
    },
    fetchPreferredLanguage: {
      error: 'user.fetch_preferred_language_error_m'
    }
  },
  searchProfile: {
    member: {
      load: {
        error: 'search_profile.member.load_error_m'
      }
    },
    save: {
      error: 'search_profile.save_error_m',
      success: 'search_profile.save_success_m'
    },
    delete: {
      error: 'search_profile.delete_error_m',
      success: 'search_profile.delete_success_m'
    },
    deactivate: {
      error: 'search_profile.deactivate_error_m',
      success: 'search_profile.deactivate_success_m'
    },
    toggle: {
      error: 'search_profile.toggle_error_m'
    },
    project: {
      fetchProject: {
        error: 'search_profile.fetch_project_error_m'
      }
    }
  },
  customQuestions: {
    answer: {
      success: 'custom_questions.send_answere_success_m',
      error: 'custom_questions.send_answers_error_m'
    },
    questions: {
      error: 'custom_questions.load_questions_error_m'
    },
    loadResponses: {
      error: 'custom_question.load_responses_error_m'
    },
    noUnansweredQuestions: 'custom_questions.no_unanswered_questions_l'
  },
  selfDisclosure: {
    loadAnswer: {
      error: 'self_disclosure.load_answers_error_m'
    },
    answer: {
      success: 'self_disclosure.send_answers_success_m',
      error: 'self_disclosure.send_answers_error_m'
    },
    load: {
      error: 'self_disclosure.load_error_m'
    }
  },
  schufa: {
    payment: {
      errorThreeTimes: 'schufa.payment_error_wrong_iban_m'
    },
    delivery: {
      error: 'schufa.delivery_error_m'
    }
  },
  digitalContract: {
    load: {
      error: 'digital_contract.load_error_l'
    },
    sendMessage: {
      success: 'digital_contract.send_message_success_l',
      error: 'digital_contract.send_message_error_l'
    },
    sendTenantInfo: {
      error: 'digital_contract.send_info_message_error_l'
    },
    startAesCheck: {
      error: 'digital_contract.schufa_account_check_error_l'
    },
    confirmAesData: {
      error: 'digital_contract.schufa_verify_data_error_l'
    },
    verifyAesCode: {
      error: 'digital_contract.verify_aes_error_l'
    },
    getAesStatus: {
      error: 'digital_contract.get_aes_status_error_l'
    },
    getSigningUrl: {
      error: 'digital_contract.get_signing_url_error_l'
    },
    download: {
      error: 'digital_contract.download_error_l'
    }
  },
  branding: {
    validate: {
      error: 'branding.validate_token_fail_m'
    }
  },
  messenger: {
    loadConversations: {
      success: 'messenger.load_conversation_success_m',
      error: 'messenger.load_conversation_fail_m'
    },
    loadConversationMessages: {
      success: 'messenger.load_conversation_messages_success_m',
      error: 'messenger.load_conversation_messages_fail_m'
    },
    sendMessage: {
      success: 'messenger.send_messages_success_m',
      error: 'messenger.send_messages_fail_m'
    }
  },
  guest: {
    checkApplication: {
      error: 'guest_mode.check_application.fail_m'
    },
    apply: {
      success: 'guest_mode.apply.success_m',
      error: 'guest_mode.apply.fail_m'
    },
    register: {
      success: 'guest_mode.register.success_m',
      error: 'guest_mode.register.fail_m'
    },
    branding: {
      error: 'guest_mode.load_branding.fail_m'
    },
    customQuestionResponses: {
      error: 'guest_mode.load_custom_question_responses.fail_m'
    },
    delete: {
      success: 'guest_mode.delete.success_m',
      error: 'guest_mode.delete.fail_m'
    },
    declareIntent: {
      success: 'guest_mode.declare_intent.success_m',
      error: 'guest_mode.declare_intent.fail_m'
    },
    acceptAppointment: {
      success: 'guest_mode.accept_appointment.success_m',
      error: 'guest_mode.accept_appointment.fail_m'
    },
    cancelAppointment: {
      success: 'guest_mode.cancel_appointment.success_m',
      error: 'guest_mode.cancel_appointment.fail_m'
    },
    loadAppointment: {
      error: 'guest_mode.load_appointment.fail_m'
    },
    loadApplication: {
      error: 'guest_mode.load_application.fail_m'
    },
    loadQuestionsResponses: {
      error: 'guest_mode.load_question_responses.fail_m'
    }
  },
  rentDeposit: {
    useRuvDeposit: {
      success: 'rental_deposit.use_ruv_deposit_success_m',
      error: 'rental_deposit.use_ruv_deposit_fail_m'
    },
    useCashDeposit: {
      success: 'rental_deposit.use_cash_deposit_success_m',
      error: 'rental_deposit.use_cash_deposit_fail_m'
    },
    loadDepositOptions: {
      error: 'rental_deposit.use_ruv_deposit_fail_m'
    }
  },
  movingChecklist: {
    changeElement: {
      success: 'moving-checklist.change_element_success_m'
    },
    reset: {
      success: 'moving-checklist.reset_success_m'
    },
    setMoveInDate: {
      success: 'moving-checklist.set_move_in_date_success_m'
    }
  },
  movingAuction: {
    sendRequest: {
      success: 'moving-auction.send_request_success_m',
      error: 'moving-auction.send_request_fail_m'
    }
  },
  serviceCards: {
    sendRequest: {
      success: 'service-cards.send_request_success_m',
      error: 'service-cards.send_request_fail_m'
    }
  },
  tooltip: {
    fetchTooltips: {
      success: 'tooltip.fetch_tooltips_success_m',
      error: 'tooltip.fetch_tooltips_fail_m'
    },
    setTooltipsAsSeen: {
      success: 'tooltip.set_tooltips_as_seen_success_m',
      error: 'tooltip.set_tooltips_as_seen_fail_m'
    },
    setTooltipTourSeen: {
      success: 'tooltip.set_tooltip_tour_as_seen_success_m',
      error: 'tooltip.set_tooltip_tour_as_seen_fail_m'
    }
  },
  payments: {
    fetchPaymentRequest: {
      error: 'payments.fetch_payment_request_fail_m'
    },
    fetchMandate: {
      error: 'payments.fetch_mandate_fail_m'
    },
    fetchPaymentBranding: {
      error: 'payments.fetch_payment_branding_fail_m'
    },
    manualPayment: {
      error: 'payments.manual_payment_fail_m'
    },
    paymentExecution: {
      error: 'payments.payment_execution_fail_m'
    }
  },
  smartDeposit: {
    loadSmartDepositFee: {
      error: 'smart-deposit.load_smart_deposit_fee_error_m'
    },
    fetchSmartDeposit: {
      error: 'smart-deposit.fetch_smart_deposit_error_m'
    },
    loadSmartDepositBranding: {
      error: 'smart-deposit.load_smart_deposit_branding_error_m'
    },
    applySmartDeposit: {
      error: 'smart-deposit.apply_smart_deposit_error_m'
    },
    cancelSmartDeposit: {
      error: 'smart-deposit.cancel_smart_deposit_error_m'
    },
    startSmartDepositLegitimation: {
      error: 'smart-deposit.start_smart_deposit_legitimation_error_m'
    },
    cancelSmartDepositLegitimation: {
      error: 'smart-deposit.cancel_smart_deposit_legitimation_error_m'
    },
    loadSmartDepositDictionary: {
      error: 'smart-deposit.load_smart_deposit_dictionary_error_m'
    }
  },
  resident: {
    verifyEmail: {
      success: 'resident.verify_email.success_m',
      error: 'resident.verify_email.error_m'
    },
    changeEmail: {
      success: 'resident.verify_email.success_m',
      error: 'resident.verify_email.error_m',
      pending: 'resident.change_email.pending_m'
    },
    resetPassword: {
      pending: 'resident.reset_password.pending_m'
    },
    invitePartner: {
      success: 'resident.invite_partner.succees_m'
    }
  }
};
