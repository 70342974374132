import { CustomerDefinedFieldValue, Property } from '@ui/shared/models';

import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './apply.actions';

function sortCustomDefinedFieldsOnProperty(
  a: CustomerDefinedFieldValue,
  b: CustomerDefinedFieldValue
): number {
  return a.definition.order - b.definition.order;
}

export interface ApplyState {
  property: Property;
  loadPropertyActionState: ActionState;
  setStorageForApplyActionState: ActionState;
}

export const initialState: ApplyState = {
  property: null,
  loadPropertyActionState: ActionStateCreator.create(),
  setStorageForApplyActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.PropertiesAction
): ApplyState {
  switch (action.type) {
    case fromActions.LOAD_PROPERTY: {
      return {
        ...state,
        loadPropertyActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_PROPERTY_FAIL: {
      return {
        ...state,
        loadPropertyActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_PROPERTY_SUCCESS: {
      let property = { ...action.data };

      if (property.customerDefinedFieldValues) {
        property = {
          ...property,
          customerDefinedFieldValues: [
            ...property.customerDefinedFieldValues
          ].sort(sortCustomDefinedFieldsOnProperty)
        };
      }

      return {
        ...state,
        property,
        loadPropertyActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SET_STORAGE_FOR_APPLY: {
      return {
        ...state,
        setStorageForApplyActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SET_STORAGE_FOR_APPLY_SUCCESS: {
      return {
        ...state,
        setStorageForApplyActionState: ActionStateCreator.onSuccess()
      };
    }

    default:
      return state;
  }
}

export const getProperty = (state: ApplyState) => state.property;
export const getLoadPropertyActionState = (state: ApplyState) =>
  state.loadPropertyActionState;
export const getSetStorageForApplyActionState = (state: ApplyState) =>
  state.setStorageForApplyActionState;
