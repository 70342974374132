import { FileUploadService, getResponseValidator } from '@ui/legacy-lib';
import { Injectable, inject } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';
import { zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  Attachment,
  AvailableLanguageCodesEnum,
  PropertySearcherDocumentTypes,
  PropertySearcherUser
} from '@ui/shared/models';

import { isValueNotNullAndUndefined } from '@ui/legacy-lib';
import {
  assignRegistrationTokenMutation,
  changeEmailMutation,
  changePasswordMutation,
  confirmEmailMutation,
  deleteUserMutation,
  firstSocialLoginQuery,
  FirstSocialLoginResponse,
  leaveInternalTenantPoolMutation,
  resendEmailVerificationMutation,
  sagaTransferDataMutation,
  changePreferredLanguageMutation,
  saveUserMutation,
  SaveUserMutationResponse,
  setSearchingStatusMutation,
  unlockAccountMutation,
  userQuery,
  UserQueryResponse,
  verifyEmailMutation,
  PreferredLanguageResponse,
  fetchPreferredLanguageQuery,
  IsRegisteredAtImmomioResponse,
  fetchIsRegisteredAtImmomioQuery,
  registerResidentMutation
} from '../queries';

@Injectable()
export class UserFacade {
  private apollo = inject(Apollo);
  private fileUploadService = inject(FileUploadService);

  private userQuery: QueryRef<UserQueryResponse>;

  public loadUser() {
    this.userQuery = this.apollo.watchQuery<UserQueryResponse>({
      query: userQuery,
      fetchPolicy: 'no-cache'
    });

    return this.userQuery.valueChanges.pipe(
      tap(getResponseValidator<UserQueryResponse>()),
      map(res => res.data.user)
    );
  }

  public firstSocialLogin(email: string) {
    return this.apollo
      .query<FirstSocialLoginResponse>({
        query: firstSocialLoginQuery,
        variables: { email },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(result => result?.data?.firstSocialLogin));
  }

  public saveUser(data: PropertySearcherUser) {
    return this.saveUserFiles(data).pipe(
      switchMap(user =>
        this.apollo
          .mutate<SaveUserMutationResponse>({
            mutation: saveUserMutation,
            variables: { user }
          })
          .pipe(map(result => result && result.data && result.data.saveUser))
      )
    );
  }

  public deleteUser(session_state: string, redirectUri: string) {
    return this.apollo
      .mutate({
        mutation: deleteUserMutation,
        variables: { input: { session_state, redirectUri } }
      })
      .pipe(
        tap(getResponseValidator<{ deleteUser: { redirectUri: string } }>()),
        map(result => result.data.deleteUser)
      );
  }

  public changeEmail(email: string) {
    return this.apollo.mutate({
      mutation: changeEmailMutation,
      variables: { email }
    });
  }

  public confirmEmail(token: string) {
    return this.apollo.mutate({
      mutation: confirmEmailMutation,
      variables: { token }
    });
  }

  public changePassword(password: string, confirmedPassword: string) {
    return this.apollo.mutate({
      mutation: changePasswordMutation,
      variables: { password, confirmedPassword }
    });
  }

  public resendEmailVerification(userId: string) {
    return this.apollo.mutate({
      mutation: resendEmailVerificationMutation,
      variables: { userId }
    });
  }

  public verifyEmail(token: string) {
    return this.apollo.mutate({
      mutation: verifyEmailMutation,
      variables: { token }
    });
  }

  public setSearchingStatus(token: string, isSearching: boolean) {
    return this.apollo.mutate({
      mutation: setSearchingStatusMutation,
      variables: { token, isSearching }
    });
  }

  public unlockAccount(token: string) {
    return this.apollo.mutate({
      mutation: unlockAccountMutation,
      variables: { token }
    });
  }

  public LeaveInternalTenantPool() {
    return this.apollo.mutate({
      mutation: leaveInternalTenantPoolMutation,
      update: () => this.refetch()
    });
  }

  public assignRegistrationToken(tokenId: string) {
    return this.apollo.mutate({
      mutation: assignRegistrationTokenMutation,
      variables: { tokenId }
    });
  }

  public sagaDataTransfer(accept: boolean, token: string) {
    return this.apollo.mutate({
      mutation: sagaTransferDataMutation,
      variables: { accept, token }
    });
  }

  public changePreferredLanguage(languageCode: AvailableLanguageCodesEnum) {
    return this.apollo.mutate({
      mutation: changePreferredLanguageMutation,
      variables: { languageCode }
    });
  }

  public fetchPreferredLanguage() {
    return this.apollo
      .query<PreferredLanguageResponse>({
        query: fetchPreferredLanguageQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(map(result => result.data.preferredLanguage));
  }

  public fetchIsRegisteredAtImmomio() {
    return this.apollo
      .query<IsRegisteredAtImmomioResponse>({
        query: fetchIsRegisteredAtImmomioQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(map(result => result.data.isRegisteredAtImmomio));
  }

  public registerResident() {
    return this.apollo.mutate({
      mutation: registerResidentMutation
    });
  }

  private saveUserFiles(user: PropertySearcherUser) {
    /* eslint-disable prefer-const */
    const {
      creditScore,
      incomeProof,
      wbsDocument,
      otherDocuments,
      ...profile
    } = user.profile;
    /* eslint-enable prefer-const */

    const wbsDocuments =
      isValueNotNullAndUndefined(wbsDocument) && !Array.isArray(wbsDocument)
        ? [wbsDocument]
        : wbsDocument;
    const creditScores =
      isValueNotNullAndUndefined(creditScore) && !Array.isArray(creditScore)
        ? [creditScore]
        : creditScore;

    return zip(
      this.fileUploadService.uploadImage(profile.portrait as Attachment),
      this.fileUploadService.uploadDocuments(
        otherDocuments,
        PropertySearcherDocumentTypes.SHARED_DOCUMENT
      ),
      this.fileUploadService.uploadDocuments(
        incomeProof,
        PropertySearcherDocumentTypes.INCOME_STATEMENT
      ),
      this.fileUploadService.uploadDocuments(
        creditScores,
        PropertySearcherDocumentTypes.CREDIT_REPORT
      ),
      this.fileUploadService.uploadDocuments(
        wbsDocuments,
        PropertySearcherDocumentTypes.WB_CERTIFICATE
      )
    ).pipe(
      map(
        ([
          portrait,
          otherAttachments,
          incomeProofAttachments,
          creditScoreAttachments,
          wbsAttachments
        ]) => {
          return {
            ...user,
            profile: {
              ...profile,
              portrait,
              attachments: [
                ...(creditScoreAttachments || []),
                ...(wbsAttachments || []),
                ...(incomeProofAttachments || []),
                ...(otherAttachments || [])
              ].filter(attachment => !!attachment)
            }
          };
        }
      )
    );
  }

  public refetch(): void {
    if (!this.userQuery) return;
    void this.userQuery.refetch();
  }
}
