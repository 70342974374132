import { Component, Input, inject } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { MainPageNavigation } from 'tenant-pool/config';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ComponentsModule } from '@ui/legacy-lib';
import { Go } from '@ui/legacy-lib';

@Component({
  selector: 'app-confirm-apply-modal',
  templateUrl: './confirm-apply-modal.component.html',
  styleUrls: ['./confirm-apply-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, RouterLink, NgStyle, TranslateModule, AsyncPipe]
})
export class ConfirmApplyModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject(Store);

  @Input() profileComplete: boolean;
  @Input() showSchufaLink: boolean;
  @Input() profileCompleteness$: Observable<number>;
  @Input() hideApplyNowButton = false;
  @Input() showGenericText = false;

  public editProfile() {
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    this.ngbActiveModal.close();
  }

  public onEditProfile() {
    this.cancel();
    this.editProfile();
  }
}
