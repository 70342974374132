import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { defaultAdvertising } from 'tenant-pool/config/hideTypes';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-appointment-success-modal',
  templateUrl: './appointment-success-modal.component.html',
  styleUrls: ['./appointment-success-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, RouterLink, TranslateModule]
})
export class AppointmentSuccessModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() schufaCard: boolean;

  public get showSchufaLink(): boolean {
    return this.schufaCard ?? defaultAdvertising.schufaCard;
  }

  public close(): void {
    this.ngbActiveModal.close();
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}
