import { AppointmentsBundles } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './appointments.actions';

export interface AppointmentsState {
  data: AppointmentsBundles[];

  dataActionState: ActionState;
  appointmentItemActionsState: ActionState;
}

export const initialState: AppointmentsState = {
  data: [],

  dataActionState: ActionStateCreator.create(),
  appointmentItemActionsState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.AppointmentsAction
): AppointmentsState {
  switch (action.type) {
    case fromActions.LOAD_APPOINTMENTS: {
      return {
        ...state,
        dataActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        dataActionState: ActionStateCreator.onSuccess(),
        data: action.data
      };
    }

    case fromActions.LOAD_APPOINTMENTS_FAIL: {
      return {
        ...state,
        dataActionState: ActionStateCreator.onError(action.error)
      };
    }
    case fromActions.SELECT_APPOINTMENT:
    case fromActions.CANCEL_APPOINTMENT:
    case fromActions.SWITCH_APPOINTMENT: {
      return {
        ...state,
        appointmentItemActionsState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SELECT_APPOINTMENT_SUCCESS:
    case fromActions.CANCEL_APPOINTMENT_SUCCESS:
    case fromActions.SWITCH_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        appointmentItemActionsState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SELECT_APPOINTMENT_FAIL:
    case fromActions.CANCEL_APPOINTMENT_FAIL:
    case fromActions.SWITCH_APPOINTMENT_FAIL: {
      return {
        ...state,
        appointmentItemActionsState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.REMOVE_APPOINTMETNS_LINKED_TO_APPLICATION: {
      return {
        ...state,
        data: state.data.filter(
          appointment => appointment.applicationId !== action.id
        )
      };
    }

    default:
      return state;
  }
}

export const getAppointmentsData = (state: AppointmentsState) => state.data;
export const getAppointmentsActionState = (state: AppointmentsState) =>
  state.dataActionState;
export const getAppointmentsItemActionState = (state: AppointmentsState) =>
  state.appointmentItemActionsState;
