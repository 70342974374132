import { Action } from '@ngrx/store';
import { Appointment } from '@ui/shared/models';
import {
  CancelAppointmentData,
  AppointmentsBundles,
  AppointmentSelection,
  AppointmentPropertyShortInfo
} from '@ui/shared/models';

export const LOAD_APPOINTMENTS = '[Appointments] Load Appointments';
export const LOAD_APPOINTMENTS_SUCCESS =
  '[Appointments] Load Appointments Success';
export const LOAD_APPOINTMENTS_FAIL = '[Appointments] Load Appointments Fail';

export class LoadAppointments implements Action {
  readonly type = LOAD_APPOINTMENTS;
}

export class LoadAppointmentsSuccess implements Action {
  readonly type = LOAD_APPOINTMENTS_SUCCESS;
  constructor(public data: AppointmentsBundles[]) {}
}

export class LoadAppointmentsFail implements Action {
  readonly type = LOAD_APPOINTMENTS_FAIL;
  constructor(public error: Error) {}
}

export const SELECT_APPOINTMENT = '[Appointments] Select Appointment';
export const SELECT_APPOINTMENT_SUCCESS =
  '[Appointments] Select Appointment Success';
export const SELECT_APPOINTMENT_FAIL = '[Appointments] Select Appointment Fail';

export class SelectAppointment implements Action {
  readonly type = SELECT_APPOINTMENT;
  constructor(public data: AppointmentSelection) {}
}

export class SelectAppointmentSuccess implements Action {
  readonly type = SELECT_APPOINTMENT_SUCCESS;
  constructor(public data: AppointmentSelection) {}
}

export class SelectAppointmentFail implements Action {
  readonly type = SELECT_APPOINTMENT_FAIL;
  constructor(public error: Error) {}
}

export const CANCEL_APPOINTMENT = '[Appointments] Cancel Appointment';
export const CANCEL_APPOINTMENT_SUCCESS =
  '[Appointments] Cancel Appointment Success';
export const CANCEL_APPOINTMENT_FAIL = '[Appointments] Cancel Appointment Fail';

export class CancelAppointment implements Action {
  readonly type = CANCEL_APPOINTMENT;
  constructor(public cancelAppointmentData: CancelAppointmentData) {}
}

export class CancelAppointmentSuccess implements Action {
  readonly type = CANCEL_APPOINTMENT_SUCCESS;
}

export class CancelAppointmentFail implements Action {
  readonly type = CANCEL_APPOINTMENT_FAIL;
  constructor(public error: Error) {}
}

export const SWITCH_APPOINTMENT = '[Appointments] Switch Appointment';
export const SWITCH_APPOINTMENT_SUCCESS =
  '[Appointments] Switch Appointment Success';
export const SWITCH_APPOINTMENT_FAIL = '[Appointments] Switch Appointment Fail';

export class SwitchAppointment implements Action {
  readonly type = SWITCH_APPOINTMENT;
  constructor(public data: AppointmentSelection) {}
}

export class SwitchAppointmentSuccess implements Action {
  readonly type = SWITCH_APPOINTMENT_SUCCESS;
  constructor(public data: AppointmentSelection) {}
}

export class SwitchAppointmentFail implements Action {
  readonly type = SWITCH_APPOINTMENT_FAIL;
  constructor(public error: Error) {}
}

export const EXPORT_CALENDAR_FILE = `[Appointments] Export Calendar File`;
export const EXPORT_CALENDAR_FILE_FAIL = `[Appointments] Export Calendar File Fail`;
export const EXPORT_CALENDAR_FILE_SUCCESS = `[Appointments] Export Calendar File Success`;

export class ExportCalendarFile implements Action {
  readonly type = EXPORT_CALENDAR_FILE;

  constructor(
    public application: {
      property: AppointmentPropertyShortInfo;
      appointment: Appointment;
    },
    public token?: string
  ) {}
}

export class ExportCalendarFileFail implements Action {
  readonly type = EXPORT_CALENDAR_FILE_FAIL;

  constructor(public error: Error) {}
}

export class ExportCalendarFileSuccess implements Action {
  readonly type = EXPORT_CALENDAR_FILE_SUCCESS;
}

export const NO_APPOINTMENT_FITTING = '[Appointments] No Appointment Fitting';
export const NO_APPOINTMENT_FITTING_SUCCESS =
  '[Appointments] No Appointment Fitting Success';
export const NO_APPOINTMENT_FITTING_FAIL =
  '[Appointments] No Appointment Fitting Fail';

export class NoAppointmentFitting implements Action {
  readonly type = NO_APPOINTMENT_FITTING;
  constructor(public cancelAppointmentData: CancelAppointmentData) {}
}

export class NoAppointmentFittingSuccess implements Action {
  readonly type = NO_APPOINTMENT_FITTING_SUCCESS;
}

export class NoAppointmentFittingFail implements Action {
  readonly type = NO_APPOINTMENT_FITTING_FAIL;
  constructor(public error: Error) {}
}

export const REMOVE_APPOINTMETNS_LINKED_TO_APPLICATION =
  '[Appointments] Remove Appointments Linked To Application';
export class RemoveAppointmentsLinkedToApplication implements Action {
  readonly type = REMOVE_APPOINTMETNS_LINKED_TO_APPLICATION;
  constructor(public id: string) {}
}

export type AppointmentsAction =
  | LoadAppointments
  | LoadAppointmentsSuccess
  | LoadAppointmentsFail
  | SelectAppointment
  | SelectAppointmentSuccess
  | SelectAppointmentFail
  | CancelAppointment
  | CancelAppointmentSuccess
  | CancelAppointmentFail
  | SwitchAppointment
  | SwitchAppointmentSuccess
  | SwitchAppointmentFail
  | ExportCalendarFile
  | ExportCalendarFileSuccess
  | ExportCalendarFileFail
  | NoAppointmentFitting
  | NoAppointmentFittingSuccess
  | NoAppointmentFittingFail
  | RemoveAppointmentsLinkedToApplication;
