import { createReducer, on } from '@ngrx/store';
import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './resident.actions';

export interface ResidentState {
  verifyEmailActionState: ActionState;
  changeEmailActionState: ActionState;
  verifyEmailStatus: boolean;
  changeEmailStatus: boolean;
}

export const initialState: ResidentState = {
  verifyEmailActionState: ActionStateCreator.create(),
  changeEmailActionState: ActionStateCreator.create(),
  verifyEmailStatus: false,
  changeEmailStatus: false
};

export const reducer = createReducer(
  initialState,

  on(fromActions.verifyEmail, state => ({
    ...state,
    verifyEmailActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.verifyEmailSuccess, (state, { status }) => ({
    ...state,
    verifyEmailStatus: status,
    verifyEmailActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.verifyEmailFail, (state, { error }) => ({
    ...state,
    verifyEmailActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.changeEmail, state => ({
    ...state,
    changeEmailActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.changeEmailSuccess, (state, { status }) => ({
    ...state,
    changeEmailStatus: status,
    changeEmailActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.changeEmailFail, (state, { error }) => ({
    ...state,
    changeEmailActionState: ActionStateCreator.onError(error)
  }))
);

export const getVerifyEmailActionState = (state: ResidentState) =>
  state.verifyEmailActionState;
export const getChangeEmailActionState = (state: ResidentState) =>
  state.changeEmailActionState;
