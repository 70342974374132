import { gql } from 'apollo-angular';
import {
  QuestionContainer,
  HierarchicalQuestion,
  HierarchicalRootQuestion
} from '@ui/shared/models';
import {
  questionContainerFields,
  rootQuestionContainerFields
} from '@ui/legacy-lib';

export interface CustomQuestionsByPropertyQueryResponse {
  customQuestionsByProperty: QuestionContainer;
}

export interface QuestionsByCustomerQueryResponse {
  questionsByCustomer: QuestionContainer;
}

export interface QuestionsAndAnswersByCustomerQueryResponse {
  questionsAndAnswersByCustomer: QuestionContainer;
}

export interface HierarchicalQuestionRespondMutationResponse {
  hierarchicalQuestionRespond: HierarchicalQuestion;
}

export interface HierarchicalRootQuestionResponseMutationResponse {
  hierarchicalRootQuestionResponse: HierarchicalRootQuestion[];
}

export const saveCustomQuestionResponsesMutation = gql`
  mutation saveCustomQuestionResponses($input: [CustomQuestionResponseInput]) {
    saveCustomQuestionResponses(input: $input) {
      status
      statusText
    }
  }
`;

export const hierarchicalRootQuestionResponseMutation = gql`
  mutation hierarchicalRootQuestionResponse($input: [HierarchicalRootQuestionResponseInput]) {
    hierarchicalRootQuestionResponse(input: $input) {
      ${rootQuestionContainerFields}
    }
  }
`;

export const customQuestionsByPropertyQuery = gql`
  query customQuestionsByProperty($id: String!) {
    customQuestionsByProperty(id: $id) {
      ${questionContainerFields}
    }
  }
`;

export const questionsByCustomerQuery = gql`
  query questionsByCustomer($id: String!) {
    questionsByCustomer(id: $id) {
      ${questionContainerFields}
    }
  }
`;

export const questionsAndAnswersByCustomerQuery = gql`
  query questionsAndAnswersByCustomer($id: String!) {
    questionsAndAnswersByCustomer(id: $id) {
      ${questionContainerFields}
    }
  }
`;
