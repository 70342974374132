import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  map,
  catchError,
  withLatestFrom,
  switchMap,
  mergeMap
} from 'rxjs/operators';

import * as fromAppReducers from 'tenant-pool/+state/reducers';

import { MovingAuctionStepRoutes } from 'tenant-pool/components/moving-auction/moving-auction-step.routes';
import { MainPageNavigation } from 'tenant-pool/config';
import { Go, ShowError } from '@ui/legacy-lib';
import { notificationConfig as notification } from '../../config/notification.config';
import { MovingAuctionFacade } from '../../core/services/moving-auction.facade';
import * as fromActions from './moving-auction.actions';
import * as fromSelectors from './moving-auction.selectors';

@Injectable()
export class MovingAuctionEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromAppReducers.AppState>>(Store);
  private facade = inject(MovingAuctionFacade);

  nextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingAuctionWizardNextStep),
      withLatestFrom(
        this.store.select(fromSelectors.getCurrentMovingAuctionWizardStep)
      ),
      map(([_, currentStepNumber]) => {
        return new Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.MOVING_AUCTION,
            MovingAuctionStepRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  prevStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingAuctionWizardPreviousStep),
      withLatestFrom(
        this.store.select(fromSelectors.getCurrentMovingAuctionWizardStep)
      ),
      map(([_, currentStepNumber]) => {
        return new Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.MOVING_AUCTION,
            MovingAuctionStepRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  goToStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingAuctionWizardGoToStep),
      map(({ stepNumber }) => {
        return new Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.MOVING_AUCTION,
            MovingAuctionStepRoutes[stepNumber].path
          ]
        });
      })
    )
  );

  sendRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingAuctionSendRequest),
      switchMap(({ movingAuctionForm }) =>
        this.facade.sendRequest(movingAuctionForm).pipe(
          mergeMap(movingAuctionResponse => [
            fromActions.MovingAuctionSendRequestSuccess({
              movingAuctionResponse
            })
            // new ShowInfo(
            //   notification.movingAuction.sendRequest.success
            // )
          ]),
          catchError(error => [
            fromActions.MovingAuctionSendRequestFail({ error }),
            new ShowError(notification.movingAuction.sendRequest.error)
          ])
        )
      )
    )
  );
}
